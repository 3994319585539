.category_img_preview {
	overflow: hidden;
	border-radius: 5px;
	width: 100px;
	height: 100px;
	margin-top: 10px;
	box-shadow: 3px 3px 4px #999;
}

.category_img_preview img {
	display: block;
	object-fit: cover;
	width: 100%;
	height: 100%;
}
