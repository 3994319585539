.img_uploader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;

	input {
		display: none;
	}

	.preview {
		width: 150px;
		height: 150px;
		position: relative;

		* {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			// top: 0;
			// right: 0;
			// bottom: 0;
			// left: 0;
		}

		label {
			width: 100%;
			height: 100%;
		}

		span {
			// border: 10px solid white;
			opacity: 0.9;
			// color: #fff;
			font-weight: 900;
			font-size: 5rem;
			right: -10px;

			* {
				stroke: #5a6;
				text-shadow: 5px 4px 4px #888;
			}
			// padding: 10px;
			// border-radius: 50%;
			// width: 30%;
			// height: 30%;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	strong {
		// border-radius: 50%;
		// width: 100px;
		// height: 100px;
		// border: 5px solid #fff;
		text-shadow: 0 0 1px #999;
		// background-color: #eee;
		color: #a02;
		cursor: pointer;

		&:hover {
			color: #009;
		}
	}
}
