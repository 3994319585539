.home_banner {
	img {
		width: 100%;
	}
	// height: 80vh;
	// background-repeat: no-repeat;
	// background-size: contain;
	// display: flex;
	// flex-direction: row;
	// justify-content: space-around;
	// align-items: center;
	// position: relative;
	// overflow: hidden;

	// .dimond {
	// 	background-color: $sky_blue;
	// 	opacity: 0.85;
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 100%;
	// 	transform: rotate(35deg);
	// 	left: -75%;
	// 	top: -25%;
	// }

	button.start_now {
		border: none;
		box-shadow: 1px 1px 2px #9999;
		border-radius: 5px;
		padding: 5px 15px;
		margin-bottom: 20px;
		align-self: flex-end;
		background-color: $orange;
		color: $white;
		font-size: 1.5rem;
	}

	> div.link {
		@include flex(row, center, center);
		width: 100%;
		height: 20%;
		// background-color: rgba(255, 255, 255, 0.6);
		// background-color: rgba(0, 0, 0, 0.6);

		> div {
			@include flex(row, center, center);
			width: 25%;
			height: 50%;
			color: $white;
			font-size: 2rem;
			text-align: center;
			text-decoration: none;
		}
	}
}

.apply_teacher {
	background-image: url('../assets/imgs/bg-02-dark.jpg');
	background-size: cover;
	background-position: center;
	padding: 100px;
}

// Teacher Page
.banner_plus_title {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;

	h1 {
		position: absolute;
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}
