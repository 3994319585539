/* Parent Categories*/
.categories_box {
	display: flex;
	flex-direction: column;
	// justify-content: center;
	// align-items: center;
	// flex-wrap: wrap;

	.category_box {
		@include flex(column, flex-start, flex-start);
		border: 2px solid $tomato;
		background-color: #eee;
		border-radius: 10px;
		margin-bottom: 50px;
		box-shadow: $sm_shadow;
		padding: 10px;

		img {
			display: block;
			width: 100%;
		}

		@media (min-width: 768px) {
			div {
				display: flex;
				flex-direction: row;
				gap: 20px;

				img {
					width: 150px;
					border-radius: 15px;
					padding: 5px;
					background-color: tomato;
					border: 3px solid #fff;
				}
			}
		}
	}
}

/* Categories Page */
.child_container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.child_box {
	border: 6px solid $tomato;
	background-color: $white;
	color: $tomato;
	font-weight: 900;
	font-size: 1.3rem;
	box-shadow: $md_shadow;
	margin: 10px;
	border-radius: 15px;
	width: 150px;
	height: 150px;
	@include flex(column, center, center);
	text-align: center;
	cursor: pointer;
}

/* Courses Page */

.courses_container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;

	.course_box {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid tomato;
		padding-bottom: 10px;
		margin-bottom: 30px;

		.course_title {
			border: 5px solid #eee;
			border-radius: 50% 45px 50% 45px;
			width: 250px;
			height: 250px;
			background-color: $tomato;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 15px;

			span {
				width: 70%;
				height: 70%;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				background-color: $white;
				align-items: center;
				text-align: center;
				color: $tomato;
				font-size: 1.5rem;
			}
		}

		@media (min-width: 768px) {
			flex-direction: row;
			gap: 30px;
			align-items: flex-start;

			.course_description {
				flex: 1;
			}
		}
	}
}
