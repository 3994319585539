.avatar {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	overflow: hidden;
	border: 5px solid $white;
	box-shadow: $md-shadow;
	// flex: 1;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}
