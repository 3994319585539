.swiper {
	width: 100%;
	// height: 350px;
	// overflow: auto;
}

.swiper-slide {
	// text-align: center;
	// font-size: 18px;
	// background: #fff;
}
