/* Sidebar */
.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: -220px;
	right: 0;
	padding: 0; /* Height of navbar */
	width: 240px;
	z-index: 1000;
}
.sidebar .sidebar-toggler {
	align-self: flex-start;
	width: 25%;
	// height: 40;
	padding: 10px;
	border-radius: 0 0 10px 0;
	background-color: $dark_gray;
	color: $white;
	@include flex(column, center, center);
	opacity: 0.7;
}

.sidebar .sidebar-items {
	display: none;
}

.position-sticky {
	position: relative;
	top: 0;
	bottom: 0;
	height: 100vh;
	// padding-top: 0.5rem;
	overflow-x: hidden;
	overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
	flex-grow: 1;
	direction: rtl;
}

.sidebar h6,
.sidebar .nav-item {
	display: none;
}

.sidebar.active {
	left: 0px;
}
.sidebar.active .sidebar-toggler {
	background-color: $tomato;
	border-radius: 50%;
	position: absolute;
	width: 24px !important;
	height: 24px;
	padding: 5px;
	left: 210px;
	top: 10px;
	opacity: 0.6;
	@include flex(column, center, center);
}

.sidebar.active h6,
.sidebar.active .nav-item {
	display: block;
}

.sidebar.active .sidebar-toggler:hover {
	opacity: 0.9;
}
.sidebar.active {
	.sidebar-items {
		display: flex;
	}
}

.sidebar.active .position-sticky {
	background-color: $white;
	box-shadow: $sm-shadow;
}
.sidebar.active .position-sticky * {
	direction: ltr;
}
.account-main {
	margin-top: 30px;
}

@media (min-width: 700px) {
	.sidebar {
		left: -190px;
	}
	.sidebar .sidebar-items {
		display: flex;
	}
}

@media (min-width: 992px) {
	.account-main {
		padding-left: 240px;
	}
	.sidebar {
		left: 0px;
	}
	.sidebar .sidebar-toggler {
		display: none !important;
	}
	.sidebar h6,
	.sidebar .nav-item {
		display: block;
	}
	.sidebar .position-sticky * {
		direction: ltr;
	}
}
