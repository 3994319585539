@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Amaranth');
@import url('https://fonts.googleapis.com/css?family=Cairo');
.txt-ghost_white {
	color: #f8f8ff;
}

.bg-ghost_white {
	background-color: #f8f8ff;
}

.txt-ghost_white-light-1 {
	color: #fefeff;
}
.txt-ghost_white-light-1:hover {
	color: dark-comp(#f8f8ff);
}

.txt-ghost_white-dark-1 {
	color: #19191a;
}
.txt-ghost_white-dark-1:hover {
	color: light-comp(#f8f8ff);
}

.txt-ghost_white-light-2 {
	color: #fefeff;
}
.txt-ghost_white-light-2:hover {
	color: dark-comp(#f8f8ff);
}

.txt-ghost_white-dark-2 {
	color: #323233;
}
.txt-ghost_white-dark-2:hover {
	color: light-comp(#f8f8ff);
}

.txt-ghost_white-light-3 {
	color: #fdfdff;
}
.txt-ghost_white-light-3:hover {
	color: dark-comp(#f8f8ff);
}

.txt-ghost_white-dark-3 {
	color: #4a4a4d;
}
.txt-ghost_white-dark-3:hover {
	color: light-comp(#f8f8ff);
}

.txt-ghost_white-light-4 {
	color: #fcfcff;
}
.txt-ghost_white-light-4:hover {
	color: dark-comp(#f8f8ff);
}

.txt-ghost_white-dark-4 {
	color: #636366;
}
.txt-ghost_white-dark-4:hover {
	color: light-comp(#f8f8ff);
}

.txt-ghost_white-light-5 {
	color: #fcfcff;
}
.txt-ghost_white-light-5:hover {
	color: dark-comp(#f8f8ff);
}

.txt-ghost_white-dark-5 {
	color: #7c7c80;
}
.txt-ghost_white-dark-5:hover {
	color: light-comp(#f8f8ff);
}

.txt-ghost_white-light-6 {
	color: #fbfbff;
}
.txt-ghost_white-light-6:hover {
	color: dark-comp(#f8f8ff);
}

.txt-ghost_white-dark-6 {
	color: #959599;
}
.txt-ghost_white-dark-6:hover {
	color: light-comp(#f8f8ff);
}

.txt-ghost_white-light-7 {
	color: #fafaff;
}
.txt-ghost_white-light-7:hover {
	color: dark-comp(#f8f8ff);
}

.txt-ghost_white-dark-7 {
	color: #aeaeb3;
}
.txt-ghost_white-dark-7:hover {
	color: light-comp(#f8f8ff);
}

.txt-turquoise {
	color: #40e0d0;
}

.bg-turquoise {
	background-color: #40e0d0;
}

.txt-turquoise-light-1 {
	color: #ecfcfa;
}
.txt-turquoise-light-1:hover {
	color: dark-comp(#40e0d0);
}

.txt-turquoise-dark-1 {
	color: #061615;
}
.txt-turquoise-dark-1:hover {
	color: light-comp(#40e0d0);
}

.txt-turquoise-light-2 {
	color: #d9f9f6;
}
.txt-turquoise-light-2:hover {
	color: dark-comp(#40e0d0);
}

.txt-turquoise-dark-2 {
	color: #0d2d2a;
}
.txt-turquoise-dark-2:hover {
	color: light-comp(#40e0d0);
}

.txt-turquoise-light-3 {
	color: #c6f6f1;
}
.txt-turquoise-light-3:hover {
	color: dark-comp(#40e0d0);
}

.txt-turquoise-dark-3 {
	color: #13433e;
}
.txt-turquoise-dark-3:hover {
	color: light-comp(#40e0d0);
}

.txt-turquoise-light-4 {
	color: #b3f3ec;
}
.txt-turquoise-light-4:hover {
	color: dark-comp(#40e0d0);
}

.txt-turquoise-dark-4 {
	color: #1a5a53;
}
.txt-turquoise-dark-4:hover {
	color: light-comp(#40e0d0);
}

.txt-turquoise-light-5 {
	color: #a0f0e8;
}
.txt-turquoise-light-5:hover {
	color: dark-comp(#40e0d0);
}

.txt-turquoise-dark-5 {
	color: #207068;
}
.txt-turquoise-dark-5:hover {
	color: light-comp(#40e0d0);
}

.txt-turquoise-light-6 {
	color: #8cece3;
}
.txt-turquoise-light-6:hover {
	color: dark-comp(#40e0d0);
}

.txt-turquoise-dark-6 {
	color: #26867d;
}
.txt-turquoise-dark-6:hover {
	color: light-comp(#40e0d0);
}

.txt-turquoise-light-7 {
	color: #79e9de;
}
.txt-turquoise-light-7:hover {
	color: dark-comp(#40e0d0);
}

.txt-turquoise-dark-7 {
	color: #2d9d92;
}
.txt-turquoise-dark-7:hover {
	color: light-comp(#40e0d0);
}

.txt-violet {
	color: #ee82ee;
}

.bg-violet {
	background-color: #ee82ee;
}

.txt-violet-light-1 {
	color: #fdf3fd;
}
.txt-violet-light-1:hover {
	color: dark-comp(#ee82ee);
}

.txt-violet-dark-1 {
	color: #180d18;
}
.txt-violet-dark-1:hover {
	color: light-comp(#ee82ee);
}

.txt-violet-light-2 {
	color: #fce6fc;
}
.txt-violet-light-2:hover {
	color: dark-comp(#ee82ee);
}

.txt-violet-dark-2 {
	color: #301a30;
}
.txt-violet-dark-2:hover {
	color: light-comp(#ee82ee);
}

.txt-violet-light-3 {
	color: #fadafa;
}
.txt-violet-light-3:hover {
	color: dark-comp(#ee82ee);
}

.txt-violet-dark-3 {
	color: #472747;
}
.txt-violet-dark-3:hover {
	color: light-comp(#ee82ee);
}

.txt-violet-light-4 {
	color: #f8cdf8;
}
.txt-violet-light-4:hover {
	color: dark-comp(#ee82ee);
}

.txt-violet-dark-4 {
	color: #5f345f;
}
.txt-violet-dark-4:hover {
	color: light-comp(#ee82ee);
}

.txt-violet-light-5 {
	color: #f7c1f7;
}
.txt-violet-light-5:hover {
	color: dark-comp(#ee82ee);
}

.txt-violet-dark-5 {
	color: #774177;
}
.txt-violet-dark-5:hover {
	color: light-comp(#ee82ee);
}

.txt-violet-light-6 {
	color: #f5b4f5;
}
.txt-violet-light-6:hover {
	color: dark-comp(#ee82ee);
}

.txt-violet-dark-6 {
	color: #8f4e8f;
}
.txt-violet-dark-6:hover {
	color: light-comp(#ee82ee);
}

.txt-violet-light-7 {
	color: #f3a8f3;
}
.txt-violet-light-7:hover {
	color: dark-comp(#ee82ee);
}

.txt-violet-dark-7 {
	color: #a75ba7;
}
.txt-violet-dark-7:hover {
	color: light-comp(#ee82ee);
}

.txt-gainsboro {
	color: #dcdcdc;
}

.bg-gainsboro {
	background-color: #dcdcdc;
}

.txt-gainsboro-light-1 {
	color: #fcfcfc;
}
.txt-gainsboro-light-1:hover {
	color: dark-comp(#dcdcdc);
}

.txt-gainsboro-dark-1 {
	color: #161616;
}
.txt-gainsboro-dark-1:hover {
	color: light-comp(#dcdcdc);
}

.txt-gainsboro-light-2 {
	color: #f8f8f8;
}
.txt-gainsboro-light-2:hover {
	color: dark-comp(#dcdcdc);
}

.txt-gainsboro-dark-2 {
	color: #2c2c2c;
}
.txt-gainsboro-dark-2:hover {
	color: light-comp(#dcdcdc);
}

.txt-gainsboro-light-3 {
	color: whitesmoke;
}
.txt-gainsboro-light-3:hover {
	color: dark-comp(#dcdcdc);
}

.txt-gainsboro-dark-3 {
	color: #424242;
}
.txt-gainsboro-dark-3:hover {
	color: light-comp(#dcdcdc);
}

.txt-gainsboro-light-4 {
	color: #f1f1f1;
}
.txt-gainsboro-light-4:hover {
	color: dark-comp(#dcdcdc);
}

.txt-gainsboro-dark-4 {
	color: #585858;
}
.txt-gainsboro-dark-4:hover {
	color: light-comp(#dcdcdc);
}

.txt-gainsboro-light-5 {
	color: #eeeeee;
}
.txt-gainsboro-light-5:hover {
	color: dark-comp(#dcdcdc);
}

.txt-gainsboro-dark-5 {
	color: #6e6e6e;
}
.txt-gainsboro-dark-5:hover {
	color: light-comp(#dcdcdc);
}

.txt-gainsboro-light-6 {
	color: #eaeaea;
}
.txt-gainsboro-light-6:hover {
	color: dark-comp(#dcdcdc);
}

.txt-gainsboro-dark-6 {
	color: #848484;
}
.txt-gainsboro-dark-6:hover {
	color: light-comp(#dcdcdc);
}

.txt-gainsboro-light-7 {
	color: #e7e7e7;
}
.txt-gainsboro-light-7:hover {
	color: dark-comp(#dcdcdc);
}

.txt-gainsboro-dark-7 {
	color: #9a9a9a;
}
.txt-gainsboro-dark-7:hover {
	color: light-comp(#dcdcdc);
}

.txt-light_goldenrod_yellow {
	color: #fafad2;
}

.bg-light_goldenrod_yellow {
	background-color: #fafad2;
}

.txt-light_goldenrod_yellow-light-1 {
	color: #fffffb;
}
.txt-light_goldenrod_yellow-light-1:hover {
	color: dark-comp(#fafad2);
}

.txt-light_goldenrod_yellow-dark-1 {
	color: #191915;
}
.txt-light_goldenrod_yellow-dark-1:hover {
	color: light-comp(#fafad2);
}

.txt-light_goldenrod_yellow-light-2 {
	color: #fefef6;
}
.txt-light_goldenrod_yellow-light-2:hover {
	color: dark-comp(#fafad2);
}

.txt-light_goldenrod_yellow-dark-2 {
	color: #32322a;
}
.txt-light_goldenrod_yellow-dark-2:hover {
	color: light-comp(#fafad2);
}

.txt-light_goldenrod_yellow-light-3 {
	color: #fefef2;
}
.txt-light_goldenrod_yellow-light-3:hover {
	color: dark-comp(#fafad2);
}

.txt-light_goldenrod_yellow-dark-3 {
	color: #4b4b3f;
}
.txt-light_goldenrod_yellow-dark-3:hover {
	color: light-comp(#fafad2);
}

.txt-light_goldenrod_yellow-light-4 {
	color: #fdfded;
}
.txt-light_goldenrod_yellow-light-4:hover {
	color: dark-comp(#fafad2);
}

.txt-light_goldenrod_yellow-dark-4 {
	color: #646454;
}
.txt-light_goldenrod_yellow-dark-4:hover {
	color: light-comp(#fafad2);
}

.txt-light_goldenrod_yellow-light-5 {
	color: #fdfde9;
}
.txt-light_goldenrod_yellow-light-5:hover {
	color: dark-comp(#fafad2);
}

.txt-light_goldenrod_yellow-dark-5 {
	color: #7d7d69;
}
.txt-light_goldenrod_yellow-dark-5:hover {
	color: light-comp(#fafad2);
}

.txt-light_goldenrod_yellow-light-6 {
	color: #fcfce4;
}
.txt-light_goldenrod_yellow-light-6:hover {
	color: dark-comp(#fafad2);
}

.txt-light_goldenrod_yellow-dark-6 {
	color: #96967e;
}
.txt-light_goldenrod_yellow-dark-6:hover {
	color: light-comp(#fafad2);
}

.txt-light_goldenrod_yellow-light-7 {
	color: #fcfce0;
}
.txt-light_goldenrod_yellow-light-7:hover {
	color: dark-comp(#fafad2);
}

.txt-light_goldenrod_yellow-dark-7 {
	color: #afaf93;
}
.txt-light_goldenrod_yellow-dark-7:hover {
	color: light-comp(#fafad2);
}

.txt-peru {
	color: #cd853f;
}

.bg-peru {
	background-color: #cd853f;
}

.txt-peru-light-1 {
	color: #faf3ec;
}
.txt-peru-light-1:hover {
	color: dark-comp(#cd853f);
}

.txt-peru-dark-1 {
	color: #150d06;
}
.txt-peru-dark-1:hover {
	color: light-comp(#cd853f);
}

.txt-peru-light-2 {
	color: #f5e7d9;
}
.txt-peru-light-2:hover {
	color: dark-comp(#cd853f);
}

.txt-peru-dark-2 {
	color: #291b0d;
}
.txt-peru-dark-2:hover {
	color: light-comp(#cd853f);
}

.txt-peru-light-3 {
	color: #f0dac5;
}
.txt-peru-light-3:hover {
	color: dark-comp(#cd853f);
}

.txt-peru-dark-3 {
	color: #3e2813;
}
.txt-peru-dark-3:hover {
	color: light-comp(#cd853f);
}

.txt-peru-light-4 {
	color: #ebceb2;
}
.txt-peru-light-4:hover {
	color: dark-comp(#cd853f);
}

.txt-peru-dark-4 {
	color: #523519;
}
.txt-peru-dark-4:hover {
	color: light-comp(#cd853f);
}

.txt-peru-light-5 {
	color: #e6c29f;
}
.txt-peru-light-5:hover {
	color: dark-comp(#cd853f);
}

.txt-peru-dark-5 {
	color: #674320;
}
.txt-peru-dark-5:hover {
	color: light-comp(#cd853f);
}

.txt-peru-light-6 {
	color: #e1b68c;
}
.txt-peru-light-6:hover {
	color: dark-comp(#cd853f);
}

.txt-peru-dark-6 {
	color: #7b5026;
}
.txt-peru-dark-6:hover {
	color: light-comp(#cd853f);
}

.txt-peru-light-7 {
	color: #dcaa79;
}
.txt-peru-light-7:hover {
	color: dark-comp(#cd853f);
}

.txt-peru-dark-7 {
	color: #905d2c;
}
.txt-peru-dark-7:hover {
	color: light-comp(#cd853f);
}

.txt-medium_aquamarine {
	color: #66cdaa;
}

.bg-medium_aquamarine {
	background-color: #66cdaa;
}

.txt-medium_aquamarine-light-1 {
	color: #f0faf7;
}
.txt-medium_aquamarine-light-1:hover {
	color: dark-comp(#66cdaa);
}

.txt-medium_aquamarine-dark-1 {
	color: #0a1511;
}
.txt-medium_aquamarine-dark-1:hover {
	color: light-comp(#66cdaa);
}

.txt-medium_aquamarine-light-2 {
	color: #e0f5ee;
}
.txt-medium_aquamarine-light-2:hover {
	color: dark-comp(#66cdaa);
}

.txt-medium_aquamarine-dark-2 {
	color: #142922;
}
.txt-medium_aquamarine-dark-2:hover {
	color: light-comp(#66cdaa);
}

.txt-medium_aquamarine-light-3 {
	color: #d1f0e6;
}
.txt-medium_aquamarine-light-3:hover {
	color: dark-comp(#66cdaa);
}

.txt-medium_aquamarine-dark-3 {
	color: #1f3e33;
}
.txt-medium_aquamarine-dark-3:hover {
	color: light-comp(#66cdaa);
}

.txt-medium_aquamarine-light-4 {
	color: #c2ebdd;
}
.txt-medium_aquamarine-light-4:hover {
	color: dark-comp(#66cdaa);
}

.txt-medium_aquamarine-dark-4 {
	color: #295244;
}
.txt-medium_aquamarine-dark-4:hover {
	color: light-comp(#66cdaa);
}

.txt-medium_aquamarine-light-5 {
	color: #b3e6d5;
}
.txt-medium_aquamarine-light-5:hover {
	color: dark-comp(#66cdaa);
}

.txt-medium_aquamarine-dark-5 {
	color: #336755;
}
.txt-medium_aquamarine-dark-5:hover {
	color: light-comp(#66cdaa);
}

.txt-medium_aquamarine-light-6 {
	color: #a3e1cc;
}
.txt-medium_aquamarine-light-6:hover {
	color: dark-comp(#66cdaa);
}

.txt-medium_aquamarine-dark-6 {
	color: #3d7b66;
}
.txt-medium_aquamarine-dark-6:hover {
	color: light-comp(#66cdaa);
}

.txt-medium_aquamarine-light-7 {
	color: #94dcc4;
}
.txt-medium_aquamarine-light-7:hover {
	color: dark-comp(#66cdaa);
}

.txt-medium_aquamarine-dark-7 {
	color: #479077;
}
.txt-medium_aquamarine-dark-7:hover {
	color: light-comp(#66cdaa);
}

.txt-cadet_blue {
	color: #5f9ea0;
}

.bg-cadet_blue {
	background-color: #5f9ea0;
}

.txt-cadet_blue-light-1 {
	color: #eff5f6;
}
.txt-cadet_blue-light-1:hover {
	color: dark-comp(#5f9ea0);
}

.txt-cadet_blue-dark-1 {
	color: #0a1010;
}
.txt-cadet_blue-dark-1:hover {
	color: light-comp(#5f9ea0);
}

.txt-cadet_blue-light-2 {
	color: #dfecec;
}
.txt-cadet_blue-light-2:hover {
	color: dark-comp(#5f9ea0);
}

.txt-cadet_blue-dark-2 {
	color: #132020;
}
.txt-cadet_blue-dark-2:hover {
	color: light-comp(#5f9ea0);
}

.txt-cadet_blue-light-3 {
	color: #cfe2e3;
}
.txt-cadet_blue-light-3:hover {
	color: dark-comp(#5f9ea0);
}

.txt-cadet_blue-dark-3 {
	color: #1d2f30;
}
.txt-cadet_blue-dark-3:hover {
	color: light-comp(#5f9ea0);
}

.txt-cadet_blue-light-4 {
	color: #bfd8d9;
}
.txt-cadet_blue-light-4:hover {
	color: dark-comp(#5f9ea0);
}

.txt-cadet_blue-dark-4 {
	color: #263f40;
}
.txt-cadet_blue-dark-4:hover {
	color: light-comp(#5f9ea0);
}

.txt-cadet_blue-light-5 {
	color: #afcfd0;
}
.txt-cadet_blue-light-5:hover {
	color: dark-comp(#5f9ea0);
}

.txt-cadet_blue-dark-5 {
	color: #304f50;
}
.txt-cadet_blue-dark-5:hover {
	color: light-comp(#5f9ea0);
}

.txt-cadet_blue-light-6 {
	color: #9fc5c6;
}
.txt-cadet_blue-light-6:hover {
	color: dark-comp(#5f9ea0);
}

.txt-cadet_blue-dark-6 {
	color: #395f60;
}
.txt-cadet_blue-dark-6:hover {
	color: light-comp(#5f9ea0);
}

.txt-cadet_blue-light-7 {
	color: #8fbbbd;
}
.txt-cadet_blue-light-7:hover {
	color: dark-comp(#5f9ea0);
}

.txt-cadet_blue-dark-7 {
	color: #436f70;
}
.txt-cadet_blue-dark-7:hover {
	color: light-comp(#5f9ea0);
}

.txt-dark_sea_green {
	color: #8fbc8f;
}

.bg-dark_sea_green {
	background-color: #8fbc8f;
}

.txt-dark_sea_green-light-1 {
	color: #f4f8f4;
}
.txt-dark_sea_green-light-1:hover {
	color: dark-comp(#8fbc8f);
}

.txt-dark_sea_green-dark-1 {
	color: #0e130e;
}
.txt-dark_sea_green-dark-1:hover {
	color: light-comp(#8fbc8f);
}

.txt-dark_sea_green-light-2 {
	color: #e9f2e9;
}
.txt-dark_sea_green-light-2:hover {
	color: dark-comp(#8fbc8f);
}

.txt-dark_sea_green-dark-2 {
	color: #1d261d;
}
.txt-dark_sea_green-dark-2:hover {
	color: light-comp(#8fbc8f);
}

.txt-dark_sea_green-light-3 {
	color: #ddebdd;
}
.txt-dark_sea_green-light-3:hover {
	color: dark-comp(#8fbc8f);
}

.txt-dark_sea_green-dark-3 {
	color: #2b382b;
}
.txt-dark_sea_green-dark-3:hover {
	color: light-comp(#8fbc8f);
}

.txt-dark_sea_green-light-4 {
	color: #d2e4d2;
}
.txt-dark_sea_green-light-4:hover {
	color: dark-comp(#8fbc8f);
}

.txt-dark_sea_green-dark-4 {
	color: #394b39;
}
.txt-dark_sea_green-dark-4:hover {
	color: light-comp(#8fbc8f);
}

.txt-dark_sea_green-light-5 {
	color: #c7dec7;
}
.txt-dark_sea_green-light-5:hover {
	color: dark-comp(#8fbc8f);
}

.txt-dark_sea_green-dark-5 {
	color: #485e48;
}
.txt-dark_sea_green-dark-5:hover {
	color: light-comp(#8fbc8f);
}

.txt-dark_sea_green-light-6 {
	color: #bcd7bc;
}
.txt-dark_sea_green-light-6:hover {
	color: dark-comp(#8fbc8f);
}

.txt-dark_sea_green-dark-6 {
	color: #567156;
}
.txt-dark_sea_green-dark-6:hover {
	color: light-comp(#8fbc8f);
}

.txt-dark_sea_green-light-7 {
	color: #b1d0b1;
}
.txt-dark_sea_green-light-7:hover {
	color: dark-comp(#8fbc8f);
}

.txt-dark_sea_green-dark-7 {
	color: #648464;
}
.txt-dark_sea_green-dark-7:hover {
	color: light-comp(#8fbc8f);
}

.txt-medium_purple {
	color: #9370db;
}

.bg-medium_purple {
	background-color: #9370db;
}

.txt-medium_purple-light-1 {
	color: #f4f1fb;
}
.txt-medium_purple-light-1:hover {
	color: dark-comp(#9370db);
}

.txt-medium_purple-dark-1 {
	color: #0f0b16;
}
.txt-medium_purple-dark-1:hover {
	color: light-comp(#9370db);
}

.txt-medium_purple-light-2 {
	color: #e9e2f8;
}
.txt-medium_purple-light-2:hover {
	color: dark-comp(#9370db);
}

.txt-medium_purple-dark-2 {
	color: #1d162c;
}
.txt-medium_purple-dark-2:hover {
	color: light-comp(#9370db);
}

.txt-medium_purple-light-3 {
	color: #dfd4f4;
}
.txt-medium_purple-light-3:hover {
	color: dark-comp(#9370db);
}

.txt-medium_purple-dark-3 {
	color: #2c2242;
}
.txt-medium_purple-dark-3:hover {
	color: light-comp(#9370db);
}

.txt-medium_purple-light-4 {
	color: #d4c6f1;
}
.txt-medium_purple-light-4:hover {
	color: dark-comp(#9370db);
}

.txt-medium_purple-dark-4 {
	color: #3b2d58;
}
.txt-medium_purple-dark-4:hover {
	color: light-comp(#9370db);
}

.txt-medium_purple-light-5 {
	color: #c9b8ed;
}
.txt-medium_purple-light-5:hover {
	color: dark-comp(#9370db);
}

.txt-medium_purple-dark-5 {
	color: #4a386e;
}
.txt-medium_purple-dark-5:hover {
	color: light-comp(#9370db);
}

.txt-medium_purple-light-6 {
	color: #bea9e9;
}
.txt-medium_purple-light-6:hover {
	color: dark-comp(#9370db);
}

.txt-medium_purple-dark-6 {
	color: #584383;
}
.txt-medium_purple-dark-6:hover {
	color: light-comp(#9370db);
}

.txt-medium_purple-light-7 {
	color: #b39be6;
}
.txt-medium_purple-light-7:hover {
	color: dark-comp(#9370db);
}

.txt-medium_purple-dark-7 {
	color: #674e99;
}
.txt-medium_purple-dark-7:hover {
	color: light-comp(#9370db);
}

.txt-tomato {
	color: #ff6347;
}

.bg-tomato {
	background-color: #ff6347;
}

.txt-tomato-light-1 {
	color: #ffefed;
}
.txt-tomato-light-1:hover {
	color: dark-comp(#ff6347);
}

.txt-tomato-dark-1 {
	color: #1a0a07;
}
.txt-tomato-dark-1:hover {
	color: light-comp(#ff6347);
}

.txt-tomato-light-2 {
	color: #ffe0da;
}
.txt-tomato-light-2:hover {
	color: dark-comp(#ff6347);
}

.txt-tomato-dark-2 {
	color: #33140e;
}
.txt-tomato-dark-2:hover {
	color: light-comp(#ff6347);
}

.txt-tomato-light-3 {
	color: #ffd0c8;
}
.txt-tomato-light-3:hover {
	color: dark-comp(#ff6347);
}

.txt-tomato-dark-3 {
	color: #4d1e15;
}
.txt-tomato-dark-3:hover {
	color: light-comp(#ff6347);
}

.txt-tomato-light-4 {
	color: #ffc1b5;
}
.txt-tomato-light-4:hover {
	color: dark-comp(#ff6347);
}

.txt-tomato-dark-4 {
	color: #66281c;
}
.txt-tomato-dark-4:hover {
	color: light-comp(#ff6347);
}

.txt-tomato-light-5 {
	color: #ffb1a3;
}
.txt-tomato-light-5:hover {
	color: dark-comp(#ff6347);
}

.txt-tomato-dark-5 {
	color: #803224;
}
.txt-tomato-dark-5:hover {
	color: light-comp(#ff6347);
}

.txt-tomato-light-6 {
	color: #ffa191;
}
.txt-tomato-light-6:hover {
	color: dark-comp(#ff6347);
}

.txt-tomato-dark-6 {
	color: #993b2b;
}
.txt-tomato-dark-6:hover {
	color: light-comp(#ff6347);
}

.txt-tomato-light-7 {
	color: #ff927e;
}
.txt-tomato-light-7:hover {
	color: dark-comp(#ff6347);
}

.txt-tomato-dark-7 {
	color: #b34532;
}
.txt-tomato-dark-7:hover {
	color: light-comp(#ff6347);
}

.txt-red {
	color: #ff0000;
}

.bg-red {
	background-color: #ff0000;
}

.txt-red-light-1 {
	color: #ffe6e6;
}
.txt-red-light-1:hover {
	color: dark-comp(#ff0000);
}

.txt-red-dark-1 {
	color: #1a0000;
}
.txt-red-dark-1:hover {
	color: light-comp(#ff0000);
}

.txt-red-light-2 {
	color: #ffcccc;
}
.txt-red-light-2:hover {
	color: dark-comp(#ff0000);
}

.txt-red-dark-2 {
	color: #330000;
}
.txt-red-dark-2:hover {
	color: light-comp(#ff0000);
}

.txt-red-light-3 {
	color: #ffb3b3;
}
.txt-red-light-3:hover {
	color: dark-comp(#ff0000);
}

.txt-red-dark-3 {
	color: #4d0000;
}
.txt-red-dark-3:hover {
	color: light-comp(#ff0000);
}

.txt-red-light-4 {
	color: #ff9999;
}
.txt-red-light-4:hover {
	color: dark-comp(#ff0000);
}

.txt-red-dark-4 {
	color: #660000;
}
.txt-red-dark-4:hover {
	color: light-comp(#ff0000);
}

.txt-red-light-5 {
	color: #ff8080;
}
.txt-red-light-5:hover {
	color: dark-comp(#ff0000);
}

.txt-red-dark-5 {
	color: maroon;
}
.txt-red-dark-5:hover {
	color: light-comp(#ff0000);
}

.txt-red-light-6 {
	color: #ff6666;
}
.txt-red-light-6:hover {
	color: dark-comp(#ff0000);
}

.txt-red-dark-6 {
	color: #990000;
}
.txt-red-dark-6:hover {
	color: light-comp(#ff0000);
}

.txt-red-light-7 {
	color: #ff4d4d;
}
.txt-red-light-7:hover {
	color: dark-comp(#ff0000);
}

.txt-red-dark-7 {
	color: #b30000;
}
.txt-red-dark-7:hover {
	color: light-comp(#ff0000);
}

.txt-aqua {
	color: #00ffff;
}

.bg-aqua {
	background-color: #00ffff;
}

.txt-aqua-light-1 {
	color: #e6ffff;
}
.txt-aqua-light-1:hover {
	color: dark-comp(#00ffff);
}

.txt-aqua-dark-1 {
	color: #001a1a;
}
.txt-aqua-dark-1:hover {
	color: light-comp(#00ffff);
}

.txt-aqua-light-2 {
	color: #ccffff;
}
.txt-aqua-light-2:hover {
	color: dark-comp(#00ffff);
}

.txt-aqua-dark-2 {
	color: #003333;
}
.txt-aqua-dark-2:hover {
	color: light-comp(#00ffff);
}

.txt-aqua-light-3 {
	color: #b3ffff;
}
.txt-aqua-light-3:hover {
	color: dark-comp(#00ffff);
}

.txt-aqua-dark-3 {
	color: #004d4d;
}
.txt-aqua-dark-3:hover {
	color: light-comp(#00ffff);
}

.txt-aqua-light-4 {
	color: #99ffff;
}
.txt-aqua-light-4:hover {
	color: dark-comp(#00ffff);
}

.txt-aqua-dark-4 {
	color: #006666;
}
.txt-aqua-dark-4:hover {
	color: light-comp(#00ffff);
}

.txt-aqua-light-5 {
	color: #80ffff;
}
.txt-aqua-light-5:hover {
	color: dark-comp(#00ffff);
}

.txt-aqua-dark-5 {
	color: teal;
}
.txt-aqua-dark-5:hover {
	color: light-comp(#00ffff);
}

.txt-aqua-light-6 {
	color: #66ffff;
}
.txt-aqua-light-6:hover {
	color: dark-comp(#00ffff);
}

.txt-aqua-dark-6 {
	color: #009999;
}
.txt-aqua-dark-6:hover {
	color: light-comp(#00ffff);
}

.txt-aqua-light-7 {
	color: #4dffff;
}
.txt-aqua-light-7:hover {
	color: dark-comp(#00ffff);
}

.txt-aqua-dark-7 {
	color: #00b3b3;
}
.txt-aqua-dark-7:hover {
	color: light-comp(#00ffff);
}

.txt-gold {
	color: #ffd700;
}

.bg-gold {
	background-color: #ffd700;
}

.txt-gold-light-1 {
	color: #fffbe6;
}
.txt-gold-light-1:hover {
	color: dark-comp(#ffd700);
}

.txt-gold-dark-1 {
	color: #1a1600;
}
.txt-gold-dark-1:hover {
	color: light-comp(#ffd700);
}

.txt-gold-light-2 {
	color: #fff7cc;
}
.txt-gold-light-2:hover {
	color: dark-comp(#ffd700);
}

.txt-gold-dark-2 {
	color: #332b00;
}
.txt-gold-dark-2:hover {
	color: light-comp(#ffd700);
}

.txt-gold-light-3 {
	color: #fff3b3;
}
.txt-gold-light-3:hover {
	color: dark-comp(#ffd700);
}

.txt-gold-dark-3 {
	color: #4d4100;
}
.txt-gold-dark-3:hover {
	color: light-comp(#ffd700);
}

.txt-gold-light-4 {
	color: #ffef99;
}
.txt-gold-light-4:hover {
	color: dark-comp(#ffd700);
}

.txt-gold-dark-4 {
	color: #665600;
}
.txt-gold-dark-4:hover {
	color: light-comp(#ffd700);
}

.txt-gold-light-5 {
	color: #ffeb80;
}
.txt-gold-light-5:hover {
	color: dark-comp(#ffd700);
}

.txt-gold-dark-5 {
	color: #806c00;
}
.txt-gold-dark-5:hover {
	color: light-comp(#ffd700);
}

.txt-gold-light-6 {
	color: #ffe766;
}
.txt-gold-light-6:hover {
	color: dark-comp(#ffd700);
}

.txt-gold-dark-6 {
	color: #998100;
}
.txt-gold-dark-6:hover {
	color: light-comp(#ffd700);
}

.txt-gold-light-7 {
	color: #ffe34d;
}
.txt-gold-light-7:hover {
	color: dark-comp(#ffd700);
}

.txt-gold-dark-7 {
	color: #b39700;
}
.txt-gold-dark-7:hover {
	color: light-comp(#ffd700);
}

.txt-chocolate {
	color: #d2691e;
}

.bg-chocolate {
	background-color: #d2691e;
}

.txt-chocolate-light-1 {
	color: #fbf0e9;
}
.txt-chocolate-light-1:hover {
	color: dark-comp(#d2691e);
}

.txt-chocolate-dark-1 {
	color: #150b03;
}
.txt-chocolate-dark-1:hover {
	color: light-comp(#d2691e);
}

.txt-chocolate-light-2 {
	color: #f6e1d2;
}
.txt-chocolate-light-2:hover {
	color: dark-comp(#d2691e);
}

.txt-chocolate-dark-2 {
	color: #2a1506;
}
.txt-chocolate-dark-2:hover {
	color: light-comp(#d2691e);
}

.txt-chocolate-light-3 {
	color: #f2d2bc;
}
.txt-chocolate-light-3:hover {
	color: dark-comp(#d2691e);
}

.txt-chocolate-dark-3 {
	color: #3f2009;
}
.txt-chocolate-dark-3:hover {
	color: light-comp(#d2691e);
}

.txt-chocolate-light-4 {
	color: #edc3a5;
}
.txt-chocolate-light-4:hover {
	color: dark-comp(#d2691e);
}

.txt-chocolate-dark-4 {
	color: #542a0c;
}
.txt-chocolate-dark-4:hover {
	color: light-comp(#d2691e);
}

.txt-chocolate-light-5 {
	color: #e9b48f;
}
.txt-chocolate-light-5:hover {
	color: dark-comp(#d2691e);
}

.txt-chocolate-dark-5 {
	color: #69350f;
}
.txt-chocolate-dark-5:hover {
	color: light-comp(#d2691e);
}

.txt-chocolate-light-6 {
	color: #e4a578;
}
.txt-chocolate-light-6:hover {
	color: dark-comp(#d2691e);
}

.txt-chocolate-dark-6 {
	color: #7e3f12;
}
.txt-chocolate-dark-6:hover {
	color: light-comp(#d2691e);
}

.txt-chocolate-light-7 {
	color: #e09662;
}
.txt-chocolate-light-7:hover {
	color: dark-comp(#d2691e);
}

.txt-chocolate-dark-7 {
	color: #934a15;
}
.txt-chocolate-dark-7:hover {
	color: light-comp(#d2691e);
}

.txt-light_yellow {
	color: #ffffe0;
}

.bg-light_yellow {
	background-color: #ffffe0;
}

.txt-light_yellow-light-1 {
	color: #fffffc;
}
.txt-light_yellow-light-1:hover {
	color: dark-comp(#ffffe0);
}

.txt-light_yellow-dark-1 {
	color: #1a1a16;
}
.txt-light_yellow-dark-1:hover {
	color: light-comp(#ffffe0);
}

.txt-light_yellow-light-2 {
	color: #fffff9;
}
.txt-light_yellow-light-2:hover {
	color: dark-comp(#ffffe0);
}

.txt-light_yellow-dark-2 {
	color: #33332d;
}
.txt-light_yellow-dark-2:hover {
	color: light-comp(#ffffe0);
}

.txt-light_yellow-light-3 {
	color: #fffff6;
}
.txt-light_yellow-light-3:hover {
	color: dark-comp(#ffffe0);
}

.txt-light_yellow-dark-3 {
	color: #4d4d43;
}
.txt-light_yellow-dark-3:hover {
	color: light-comp(#ffffe0);
}

.txt-light_yellow-light-4 {
	color: #fffff3;
}
.txt-light_yellow-light-4:hover {
	color: dark-comp(#ffffe0);
}

.txt-light_yellow-dark-4 {
	color: #66665a;
}
.txt-light_yellow-dark-4:hover {
	color: light-comp(#ffffe0);
}

.txt-light_yellow-light-5 {
	color: ivory;
}
.txt-light_yellow-light-5:hover {
	color: dark-comp(#ffffe0);
}

.txt-light_yellow-dark-5 {
	color: #808070;
}
.txt-light_yellow-dark-5:hover {
	color: light-comp(#ffffe0);
}

.txt-light_yellow-light-6 {
	color: #ffffec;
}
.txt-light_yellow-light-6:hover {
	color: dark-comp(#ffffe0);
}

.txt-light_yellow-dark-6 {
	color: #999986;
}
.txt-light_yellow-dark-6:hover {
	color: light-comp(#ffffe0);
}

.txt-light_yellow-light-7 {
	color: #ffffe9;
}
.txt-light_yellow-light-7:hover {
	color: dark-comp(#ffffe0);
}

.txt-light_yellow-dark-7 {
	color: #b3b39d;
}
.txt-light_yellow-dark-7:hover {
	color: light-comp(#ffffe0);
}

.txt-khaki {
	color: #f0e68c;
}

.bg-khaki {
	background-color: #f0e68c;
}

.txt-khaki-light-1 {
	color: #fefdf4;
}
.txt-khaki-light-1:hover {
	color: dark-comp(#f0e68c);
}

.txt-khaki-dark-1 {
	color: #18170e;
}
.txt-khaki-dark-1:hover {
	color: light-comp(#f0e68c);
}

.txt-khaki-light-2 {
	color: #fcfae8;
}
.txt-khaki-light-2:hover {
	color: dark-comp(#f0e68c);
}

.txt-khaki-dark-2 {
	color: #302e1c;
}
.txt-khaki-dark-2:hover {
	color: light-comp(#f0e68c);
}

.txt-khaki-light-3 {
	color: #fbf8dd;
}
.txt-khaki-light-3:hover {
	color: dark-comp(#f0e68c);
}

.txt-khaki-dark-3 {
	color: #48452a;
}
.txt-khaki-dark-3:hover {
	color: light-comp(#f0e68c);
}

.txt-khaki-light-4 {
	color: #f9f5d1;
}
.txt-khaki-light-4:hover {
	color: dark-comp(#f0e68c);
}

.txt-khaki-dark-4 {
	color: #605c38;
}
.txt-khaki-dark-4:hover {
	color: light-comp(#f0e68c);
}

.txt-khaki-light-5 {
	color: #f8f3c6;
}
.txt-khaki-light-5:hover {
	color: dark-comp(#f0e68c);
}

.txt-khaki-dark-5 {
	color: #787346;
}
.txt-khaki-dark-5:hover {
	color: light-comp(#f0e68c);
}

.txt-khaki-light-6 {
	color: #f6f0ba;
}
.txt-khaki-light-6:hover {
	color: dark-comp(#f0e68c);
}

.txt-khaki-dark-6 {
	color: #908a54;
}
.txt-khaki-dark-6:hover {
	color: light-comp(#f0e68c);
}

.txt-khaki-light-7 {
	color: #f5eeaf;
}
.txt-khaki-light-7:hover {
	color: dark-comp(#f0e68c);
}

.txt-khaki-dark-7 {
	color: #a8a162;
}
.txt-khaki-dark-7:hover {
	color: light-comp(#f0e68c);
}

.txt-yellow_green {
	color: #9acd32;
}

.bg-yellow_green {
	background-color: #9acd32;
}

.txt-yellow_green-light-1 {
	color: #f5faeb;
}
.txt-yellow_green-light-1:hover {
	color: dark-comp(#9acd32);
}

.txt-yellow_green-dark-1 {
	color: #0f1505;
}
.txt-yellow_green-dark-1:hover {
	color: light-comp(#9acd32);
}

.txt-yellow_green-light-2 {
	color: #ebf5d6;
}
.txt-yellow_green-light-2:hover {
	color: dark-comp(#9acd32);
}

.txt-yellow_green-dark-2 {
	color: #1f290a;
}
.txt-yellow_green-dark-2:hover {
	color: light-comp(#9acd32);
}

.txt-yellow_green-light-3 {
	color: #e1f0c2;
}
.txt-yellow_green-light-3:hover {
	color: dark-comp(#9acd32);
}

.txt-yellow_green-dark-3 {
	color: #2e3e0f;
}
.txt-yellow_green-dark-3:hover {
	color: light-comp(#9acd32);
}

.txt-yellow_green-light-4 {
	color: #d7ebad;
}
.txt-yellow_green-light-4:hover {
	color: dark-comp(#9acd32);
}

.txt-yellow_green-dark-4 {
	color: #3e5214;
}
.txt-yellow_green-dark-4:hover {
	color: light-comp(#9acd32);
}

.txt-yellow_green-light-5 {
	color: #cde699;
}
.txt-yellow_green-light-5:hover {
	color: dark-comp(#9acd32);
}

.txt-yellow_green-dark-5 {
	color: #4d6719;
}
.txt-yellow_green-dark-5:hover {
	color: light-comp(#9acd32);
}

.txt-yellow_green-light-6 {
	color: #c2e184;
}
.txt-yellow_green-light-6:hover {
	color: dark-comp(#9acd32);
}

.txt-yellow_green-dark-6 {
	color: #5c7b1e;
}
.txt-yellow_green-dark-6:hover {
	color: light-comp(#9acd32);
}

.txt-yellow_green-light-7 {
	color: #b8dc70;
}
.txt-yellow_green-light-7:hover {
	color: dark-comp(#9acd32);
}

.txt-yellow_green-dark-7 {
	color: #6c9023;
}
.txt-yellow_green-dark-7:hover {
	color: light-comp(#9acd32);
}

.txt-coral {
	color: #ff7f50;
}

.bg-coral {
	background-color: #ff7f50;
}

.txt-coral-light-1 {
	color: #fff2ee;
}
.txt-coral-light-1:hover {
	color: dark-comp(#ff7f50);
}

.txt-coral-dark-1 {
	color: #1a0d08;
}
.txt-coral-dark-1:hover {
	color: light-comp(#ff7f50);
}

.txt-coral-light-2 {
	color: #ffe5dc;
}
.txt-coral-light-2:hover {
	color: dark-comp(#ff7f50);
}

.txt-coral-dark-2 {
	color: #331910;
}
.txt-coral-dark-2:hover {
	color: light-comp(#ff7f50);
}

.txt-coral-light-3 {
	color: #ffd9cb;
}
.txt-coral-light-3:hover {
	color: dark-comp(#ff7f50);
}

.txt-coral-dark-3 {
	color: #4d2618;
}
.txt-coral-dark-3:hover {
	color: light-comp(#ff7f50);
}

.txt-coral-light-4 {
	color: #ffccb9;
}
.txt-coral-light-4:hover {
	color: dark-comp(#ff7f50);
}

.txt-coral-dark-4 {
	color: #663320;
}
.txt-coral-dark-4:hover {
	color: light-comp(#ff7f50);
}

.txt-coral-light-5 {
	color: #ffbfa8;
}
.txt-coral-light-5:hover {
	color: dark-comp(#ff7f50);
}

.txt-coral-dark-5 {
	color: #804028;
}
.txt-coral-dark-5:hover {
	color: light-comp(#ff7f50);
}

.txt-coral-light-6 {
	color: #ffb296;
}
.txt-coral-light-6:hover {
	color: dark-comp(#ff7f50);
}

.txt-coral-dark-6 {
	color: #994c30;
}
.txt-coral-dark-6:hover {
	color: light-comp(#ff7f50);
}

.txt-coral-light-7 {
	color: #ffa585;
}
.txt-coral-light-7:hover {
	color: dark-comp(#ff7f50);
}

.txt-coral-dark-7 {
	color: #b35938;
}
.txt-coral-dark-7:hover {
	color: light-comp(#ff7f50);
}

.txt-snow {
	color: #fffafa;
}

.bg-snow {
	background-color: #fffafa;
}

.txt-snow-light-1 {
	color: white;
}
.txt-snow-light-1:hover {
	color: dark-comp(#fffafa);
}

.txt-snow-dark-1 {
	color: #1a1919;
}
.txt-snow-dark-1:hover {
	color: light-comp(#fffafa);
}

.txt-snow-light-2 {
	color: #fffefe;
}
.txt-snow-light-2:hover {
	color: dark-comp(#fffafa);
}

.txt-snow-dark-2 {
	color: #333232;
}
.txt-snow-dark-2:hover {
	color: light-comp(#fffafa);
}

.txt-snow-light-3 {
	color: #fffefe;
}
.txt-snow-light-3:hover {
	color: dark-comp(#fffafa);
}

.txt-snow-dark-3 {
	color: #4d4b4b;
}
.txt-snow-dark-3:hover {
	color: light-comp(#fffafa);
}

.txt-snow-light-4 {
	color: #fffdfd;
}
.txt-snow-light-4:hover {
	color: dark-comp(#fffafa);
}

.txt-snow-dark-4 {
	color: #666464;
}
.txt-snow-dark-4:hover {
	color: light-comp(#fffafa);
}

.txt-snow-light-5 {
	color: #fffdfd;
}
.txt-snow-light-5:hover {
	color: dark-comp(#fffafa);
}

.txt-snow-dark-5 {
	color: #807d7d;
}
.txt-snow-dark-5:hover {
	color: light-comp(#fffafa);
}

.txt-snow-light-6 {
	color: #fffcfc;
}
.txt-snow-light-6:hover {
	color: dark-comp(#fffafa);
}

.txt-snow-dark-6 {
	color: #999696;
}
.txt-snow-dark-6:hover {
	color: light-comp(#fffafa);
}

.txt-snow-light-7 {
	color: #fffcfc;
}
.txt-snow-light-7:hover {
	color: dark-comp(#fffafa);
}

.txt-snow-dark-7 {
	color: #b3afaf;
}
.txt-snow-dark-7:hover {
	color: light-comp(#fffafa);
}

.txt-light_salmon {
	color: #ffa07a;
}

.bg-light_salmon {
	background-color: #ffa07a;
}

.txt-light_salmon-light-1 {
	color: #fff6f2;
}
.txt-light_salmon-light-1:hover {
	color: dark-comp(#ffa07a);
}

.txt-light_salmon-dark-1 {
	color: #1a100c;
}
.txt-light_salmon-dark-1:hover {
	color: light-comp(#ffa07a);
}

.txt-light_salmon-light-2 {
	color: #ffece4;
}
.txt-light_salmon-light-2:hover {
	color: dark-comp(#ffa07a);
}

.txt-light_salmon-dark-2 {
	color: #332018;
}
.txt-light_salmon-dark-2:hover {
	color: light-comp(#ffa07a);
}

.txt-light_salmon-light-3 {
	color: #ffe3d7;
}
.txt-light_salmon-light-3:hover {
	color: dark-comp(#ffa07a);
}

.txt-light_salmon-dark-3 {
	color: #4d3025;
}
.txt-light_salmon-dark-3:hover {
	color: light-comp(#ffa07a);
}

.txt-light_salmon-light-4 {
	color: #ffd9ca;
}
.txt-light_salmon-light-4:hover {
	color: dark-comp(#ffa07a);
}

.txt-light_salmon-dark-4 {
	color: #664031;
}
.txt-light_salmon-dark-4:hover {
	color: light-comp(#ffa07a);
}

.txt-light_salmon-light-5 {
	color: #ffd0bd;
}
.txt-light_salmon-light-5:hover {
	color: dark-comp(#ffa07a);
}

.txt-light_salmon-dark-5 {
	color: #80503d;
}
.txt-light_salmon-dark-5:hover {
	color: light-comp(#ffa07a);
}

.txt-light_salmon-light-6 {
	color: #ffc6af;
}
.txt-light_salmon-light-6:hover {
	color: dark-comp(#ffa07a);
}

.txt-light_salmon-dark-6 {
	color: #996049;
}
.txt-light_salmon-dark-6:hover {
	color: light-comp(#ffa07a);
}

.txt-light_salmon-light-7 {
	color: #ffbda2;
}
.txt-light_salmon-light-7:hover {
	color: dark-comp(#ffa07a);
}

.txt-light_salmon-dark-7 {
	color: #b37055;
}
.txt-light_salmon-dark-7:hover {
	color: light-comp(#ffa07a);
}

.txt-light_sky_blue {
	color: #87cefa;
}

.bg-light_sky_blue {
	background-color: #87cefa;
}

.txt-light_sky_blue-light-1 {
	color: #f3faff;
}
.txt-light_sky_blue-light-1:hover {
	color: dark-comp(#87cefa);
}

.txt-light_sky_blue-dark-1 {
	color: #0e1519;
}
.txt-light_sky_blue-dark-1:hover {
	color: light-comp(#87cefa);
}

.txt-light_sky_blue-light-2 {
	color: #e7f5fe;
}
.txt-light_sky_blue-light-2:hover {
	color: dark-comp(#87cefa);
}

.txt-light_sky_blue-dark-2 {
	color: #1b2932;
}
.txt-light_sky_blue-dark-2:hover {
	color: light-comp(#87cefa);
}

.txt-light_sky_blue-light-3 {
	color: #dbf0fe;
}
.txt-light_sky_blue-light-3:hover {
	color: dark-comp(#87cefa);
}

.txt-light_sky_blue-dark-3 {
	color: #293e4b;
}
.txt-light_sky_blue-dark-3:hover {
	color: light-comp(#87cefa);
}

.txt-light_sky_blue-light-4 {
	color: #cfebfd;
}
.txt-light_sky_blue-light-4:hover {
	color: dark-comp(#87cefa);
}

.txt-light_sky_blue-dark-4 {
	color: #365264;
}
.txt-light_sky_blue-dark-4:hover {
	color: light-comp(#87cefa);
}

.txt-light_sky_blue-light-5 {
	color: #c3e7fd;
}
.txt-light_sky_blue-light-5:hover {
	color: dark-comp(#87cefa);
}

.txt-light_sky_blue-dark-5 {
	color: #44677d;
}
.txt-light_sky_blue-dark-5:hover {
	color: light-comp(#87cefa);
}

.txt-light_sky_blue-light-6 {
	color: #b7e2fc;
}
.txt-light_sky_blue-light-6:hover {
	color: dark-comp(#87cefa);
}

.txt-light_sky_blue-dark-6 {
	color: #517c96;
}
.txt-light_sky_blue-dark-6:hover {
	color: light-comp(#87cefa);
}

.txt-light_sky_blue-light-7 {
	color: #abddfc;
}
.txt-light_sky_blue-light-7:hover {
	color: dark-comp(#87cefa);
}

.txt-light_sky_blue-dark-7 {
	color: #5f90af;
}
.txt-light_sky_blue-dark-7:hover {
	color: light-comp(#87cefa);
}

.txt-plum {
	color: #dda0dd;
}

.bg-plum {
	background-color: #dda0dd;
}

.txt-plum-light-1 {
	color: #fcf6fc;
}
.txt-plum-light-1:hover {
	color: dark-comp(#dda0dd);
}

.txt-plum-dark-1 {
	color: #161016;
}
.txt-plum-dark-1:hover {
	color: light-comp(#dda0dd);
}

.txt-plum-light-2 {
	color: #f8ecf8;
}
.txt-plum-light-2:hover {
	color: dark-comp(#dda0dd);
}

.txt-plum-dark-2 {
	color: #2c202c;
}
.txt-plum-dark-2:hover {
	color: light-comp(#dda0dd);
}

.txt-plum-light-3 {
	color: #f5e3f5;
}
.txt-plum-light-3:hover {
	color: dark-comp(#dda0dd);
}

.txt-plum-dark-3 {
	color: #423042;
}
.txt-plum-dark-3:hover {
	color: light-comp(#dda0dd);
}

.txt-plum-light-4 {
	color: #f1d9f1;
}
.txt-plum-light-4:hover {
	color: dark-comp(#dda0dd);
}

.txt-plum-dark-4 {
	color: #584058;
}
.txt-plum-dark-4:hover {
	color: light-comp(#dda0dd);
}

.txt-plum-light-5 {
	color: #eed0ee;
}
.txt-plum-light-5:hover {
	color: dark-comp(#dda0dd);
}

.txt-plum-dark-5 {
	color: #6f506f;
}
.txt-plum-dark-5:hover {
	color: light-comp(#dda0dd);
}

.txt-plum-light-6 {
	color: #ebc6eb;
}
.txt-plum-light-6:hover {
	color: dark-comp(#dda0dd);
}

.txt-plum-dark-6 {
	color: #856085;
}
.txt-plum-dark-6:hover {
	color: light-comp(#dda0dd);
}

.txt-plum-light-7 {
	color: #e7bde7;
}
.txt-plum-light-7:hover {
	color: dark-comp(#dda0dd);
}

.txt-plum-dark-7 {
	color: #9b709b;
}
.txt-plum-dark-7:hover {
	color: light-comp(#dda0dd);
}

.txt-maroon {
	color: #800000;
}

.bg-maroon {
	background-color: #800000;
}

.txt-maroon-light-1 {
	color: #f2e6e6;
}
.txt-maroon-light-1:hover {
	color: dark-comp(#800000);
}

.txt-maroon-dark-1 {
	color: #0d0000;
}
.txt-maroon-dark-1:hover {
	color: light-comp(#800000);
}

.txt-maroon-light-2 {
	color: #e6cccc;
}
.txt-maroon-light-2:hover {
	color: dark-comp(#800000);
}

.txt-maroon-dark-2 {
	color: #1a0000;
}
.txt-maroon-dark-2:hover {
	color: light-comp(#800000);
}

.txt-maroon-light-3 {
	color: #d9b3b3;
}
.txt-maroon-light-3:hover {
	color: dark-comp(#800000);
}

.txt-maroon-dark-3 {
	color: #260000;
}
.txt-maroon-dark-3:hover {
	color: light-comp(#800000);
}

.txt-maroon-light-4 {
	color: #cc9999;
}
.txt-maroon-light-4:hover {
	color: dark-comp(#800000);
}

.txt-maroon-dark-4 {
	color: #330000;
}
.txt-maroon-dark-4:hover {
	color: light-comp(#800000);
}

.txt-maroon-light-5 {
	color: #c08080;
}
.txt-maroon-light-5:hover {
	color: dark-comp(#800000);
}

.txt-maroon-dark-5 {
	color: #400000;
}
.txt-maroon-dark-5:hover {
	color: light-comp(#800000);
}

.txt-maroon-light-6 {
	color: #b36666;
}
.txt-maroon-light-6:hover {
	color: dark-comp(#800000);
}

.txt-maroon-dark-6 {
	color: #4d0000;
}
.txt-maroon-dark-6:hover {
	color: light-comp(#800000);
}

.txt-maroon-light-7 {
	color: #a64d4d;
}
.txt-maroon-light-7:hover {
	color: dark-comp(#800000);
}

.txt-maroon-dark-7 {
	color: #5a0000;
}
.txt-maroon-dark-7:hover {
	color: light-comp(#800000);
}

.txt-cornflower_blue {
	color: #6495ed;
}

.bg-cornflower_blue {
	background-color: #6495ed;
}

.txt-cornflower_blue-light-1 {
	color: #f0f4fd;
}
.txt-cornflower_blue-light-1:hover {
	color: dark-comp(#6495ed);
}

.txt-cornflower_blue-dark-1 {
	color: #0a0f18;
}
.txt-cornflower_blue-dark-1:hover {
	color: light-comp(#6495ed);
}

.txt-cornflower_blue-light-2 {
	color: #e0eafb;
}
.txt-cornflower_blue-light-2:hover {
	color: dark-comp(#6495ed);
}

.txt-cornflower_blue-dark-2 {
	color: #141e2f;
}
.txt-cornflower_blue-dark-2:hover {
	color: light-comp(#6495ed);
}

.txt-cornflower_blue-light-3 {
	color: #d1dffa;
}
.txt-cornflower_blue-light-3:hover {
	color: dark-comp(#6495ed);
}

.txt-cornflower_blue-dark-3 {
	color: #1e2d47;
}
.txt-cornflower_blue-dark-3:hover {
	color: light-comp(#6495ed);
}

.txt-cornflower_blue-light-4 {
	color: #c1d5f8;
}
.txt-cornflower_blue-light-4:hover {
	color: dark-comp(#6495ed);
}

.txt-cornflower_blue-dark-4 {
	color: #283c5f;
}
.txt-cornflower_blue-dark-4:hover {
	color: light-comp(#6495ed);
}

.txt-cornflower_blue-light-5 {
	color: #b2caf6;
}
.txt-cornflower_blue-light-5:hover {
	color: dark-comp(#6495ed);
}

.txt-cornflower_blue-dark-5 {
	color: #324b77;
}
.txt-cornflower_blue-dark-5:hover {
	color: light-comp(#6495ed);
}

.txt-cornflower_blue-light-6 {
	color: #a2bff4;
}
.txt-cornflower_blue-light-6:hover {
	color: dark-comp(#6495ed);
}

.txt-cornflower_blue-dark-6 {
	color: #3c598e;
}
.txt-cornflower_blue-dark-6:hover {
	color: light-comp(#6495ed);
}

.txt-cornflower_blue-light-7 {
	color: #93b5f2;
}
.txt-cornflower_blue-light-7:hover {
	color: dark-comp(#6495ed);
}

.txt-cornflower_blue-dark-7 {
	color: #4668a6;
}
.txt-cornflower_blue-dark-7:hover {
	color: light-comp(#6495ed);
}

.txt-cornsilk {
	color: #fff8dc;
}

.bg-cornsilk {
	background-color: #fff8dc;
}

.txt-cornsilk-light-1 {
	color: #fffefc;
}
.txt-cornsilk-light-1:hover {
	color: dark-comp(#fff8dc);
}

.txt-cornsilk-dark-1 {
	color: #1a1916;
}
.txt-cornsilk-dark-1:hover {
	color: light-comp(#fff8dc);
}

.txt-cornsilk-light-2 {
	color: #fffef8;
}
.txt-cornsilk-light-2:hover {
	color: dark-comp(#fff8dc);
}

.txt-cornsilk-dark-2 {
	color: #33322c;
}
.txt-cornsilk-dark-2:hover {
	color: light-comp(#fff8dc);
}

.txt-cornsilk-light-3 {
	color: #fffdf5;
}
.txt-cornsilk-light-3:hover {
	color: dark-comp(#fff8dc);
}

.txt-cornsilk-dark-3 {
	color: #4d4a42;
}
.txt-cornsilk-dark-3:hover {
	color: light-comp(#fff8dc);
}

.txt-cornsilk-light-4 {
	color: #fffcf1;
}
.txt-cornsilk-light-4:hover {
	color: dark-comp(#fff8dc);
}

.txt-cornsilk-dark-4 {
	color: #666358;
}
.txt-cornsilk-dark-4:hover {
	color: light-comp(#fff8dc);
}

.txt-cornsilk-light-5 {
	color: #fffcee;
}
.txt-cornsilk-light-5:hover {
	color: dark-comp(#fff8dc);
}

.txt-cornsilk-dark-5 {
	color: #807c6e;
}
.txt-cornsilk-dark-5:hover {
	color: light-comp(#fff8dc);
}

.txt-cornsilk-light-6 {
	color: #fffbea;
}
.txt-cornsilk-light-6:hover {
	color: dark-comp(#fff8dc);
}

.txt-cornsilk-dark-6 {
	color: #999584;
}
.txt-cornsilk-dark-6:hover {
	color: light-comp(#fff8dc);
}

.txt-cornsilk-light-7 {
	color: #fffae7;
}
.txt-cornsilk-light-7:hover {
	color: dark-comp(#fff8dc);
}

.txt-cornsilk-dark-7 {
	color: #b3ae9a;
}
.txt-cornsilk-dark-7:hover {
	color: light-comp(#fff8dc);
}

.txt-salmon {
	color: #fa8072;
}

.bg-salmon {
	background-color: #fa8072;
}

.txt-salmon-light-1 {
	color: #fff2f1;
}
.txt-salmon-light-1:hover {
	color: dark-comp(#fa8072);
}

.txt-salmon-dark-1 {
	color: #190d0b;
}
.txt-salmon-dark-1:hover {
	color: light-comp(#fa8072);
}

.txt-salmon-light-2 {
	color: #fee6e3;
}
.txt-salmon-light-2:hover {
	color: dark-comp(#fa8072);
}

.txt-salmon-dark-2 {
	color: #321a17;
}
.txt-salmon-dark-2:hover {
	color: light-comp(#fa8072);
}

.txt-salmon-light-3 {
	color: #fed9d5;
}
.txt-salmon-light-3:hover {
	color: dark-comp(#fa8072);
}

.txt-salmon-dark-3 {
	color: #4b2622;
}
.txt-salmon-dark-3:hover {
	color: light-comp(#fa8072);
}

.txt-salmon-light-4 {
	color: #fdccc7;
}
.txt-salmon-light-4:hover {
	color: dark-comp(#fa8072);
}

.txt-salmon-dark-4 {
	color: #64332e;
}
.txt-salmon-dark-4:hover {
	color: light-comp(#fa8072);
}

.txt-salmon-light-5 {
	color: #fdc0b9;
}
.txt-salmon-light-5:hover {
	color: dark-comp(#fa8072);
}

.txt-salmon-dark-5 {
	color: #7d4039;
}
.txt-salmon-dark-5:hover {
	color: light-comp(#fa8072);
}

.txt-salmon-light-6 {
	color: #fcb3aa;
}
.txt-salmon-light-6:hover {
	color: dark-comp(#fa8072);
}

.txt-salmon-dark-6 {
	color: #964d44;
}
.txt-salmon-dark-6:hover {
	color: light-comp(#fa8072);
}

.txt-salmon-light-7 {
	color: #fca69c;
}
.txt-salmon-light-7:hover {
	color: dark-comp(#fa8072);
}

.txt-salmon-dark-7 {
	color: #af5a50;
}
.txt-salmon-dark-7:hover {
	color: light-comp(#fa8072);
}

.txt-purple {
	color: #800080;
}

.bg-purple {
	background-color: #800080;
}

.txt-purple-light-1 {
	color: #f2e6f2;
}
.txt-purple-light-1:hover {
	color: dark-comp(#800080);
}

.txt-purple-dark-1 {
	color: #0d000d;
}
.txt-purple-dark-1:hover {
	color: light-comp(#800080);
}

.txt-purple-light-2 {
	color: #e6cce6;
}
.txt-purple-light-2:hover {
	color: dark-comp(#800080);
}

.txt-purple-dark-2 {
	color: #1a001a;
}
.txt-purple-dark-2:hover {
	color: light-comp(#800080);
}

.txt-purple-light-3 {
	color: #d9b3d9;
}
.txt-purple-light-3:hover {
	color: dark-comp(#800080);
}

.txt-purple-dark-3 {
	color: #260026;
}
.txt-purple-dark-3:hover {
	color: light-comp(#800080);
}

.txt-purple-light-4 {
	color: #cc99cc;
}
.txt-purple-light-4:hover {
	color: dark-comp(#800080);
}

.txt-purple-dark-4 {
	color: #330033;
}
.txt-purple-dark-4:hover {
	color: light-comp(#800080);
}

.txt-purple-light-5 {
	color: #c080c0;
}
.txt-purple-light-5:hover {
	color: dark-comp(#800080);
}

.txt-purple-dark-5 {
	color: #400040;
}
.txt-purple-dark-5:hover {
	color: light-comp(#800080);
}

.txt-purple-light-6 {
	color: #b366b3;
}
.txt-purple-light-6:hover {
	color: dark-comp(#800080);
}

.txt-purple-dark-6 {
	color: #4d004d;
}
.txt-purple-dark-6:hover {
	color: light-comp(#800080);
}

.txt-purple-light-7 {
	color: #a64da6;
}
.txt-purple-light-7:hover {
	color: dark-comp(#800080);
}

.txt-purple-dark-7 {
	color: #5a005a;
}
.txt-purple-dark-7:hover {
	color: light-comp(#800080);
}

.txt-slate_gray {
	color: #708090;
}

.bg-slate_gray {
	background-color: #708090;
}

.txt-slate_gray-light-1 {
	color: #f1f2f4;
}
.txt-slate_gray-light-1:hover {
	color: dark-comp(#708090);
}

.txt-slate_gray-dark-1 {
	color: #0b0d0e;
}
.txt-slate_gray-dark-1:hover {
	color: light-comp(#708090);
}

.txt-slate_gray-light-2 {
	color: #e2e6e9;
}
.txt-slate_gray-light-2:hover {
	color: dark-comp(#708090);
}

.txt-slate_gray-dark-2 {
	color: #161a1d;
}
.txt-slate_gray-dark-2:hover {
	color: light-comp(#708090);
}

.txt-slate_gray-light-3 {
	color: #d4d9de;
}
.txt-slate_gray-light-3:hover {
	color: dark-comp(#708090);
}

.txt-slate_gray-dark-3 {
	color: #22262b;
}
.txt-slate_gray-dark-3:hover {
	color: light-comp(#708090);
}

.txt-slate_gray-light-4 {
	color: #c6ccd3;
}
.txt-slate_gray-light-4:hover {
	color: dark-comp(#708090);
}

.txt-slate_gray-dark-4 {
	color: #2d333a;
}
.txt-slate_gray-dark-4:hover {
	color: light-comp(#708090);
}

.txt-slate_gray-light-5 {
	color: #b8c0c8;
}
.txt-slate_gray-light-5:hover {
	color: dark-comp(#708090);
}

.txt-slate_gray-dark-5 {
	color: #384048;
}
.txt-slate_gray-dark-5:hover {
	color: light-comp(#708090);
}

.txt-slate_gray-light-6 {
	color: #a9b3bc;
}
.txt-slate_gray-light-6:hover {
	color: dark-comp(#708090);
}

.txt-slate_gray-dark-6 {
	color: #434d56;
}
.txt-slate_gray-dark-6:hover {
	color: light-comp(#708090);
}

.txt-slate_gray-light-7 {
	color: #9ba6b1;
}
.txt-slate_gray-light-7:hover {
	color: dark-comp(#708090);
}

.txt-slate_gray-dark-7 {
	color: #4e5a65;
}
.txt-slate_gray-dark-7:hover {
	color: light-comp(#708090);
}

.txt-orange {
	color: #ffa500;
}

.bg-orange {
	background-color: #ffa500;
}

.txt-orange-light-1 {
	color: #fff6e6;
}
.txt-orange-light-1:hover {
	color: dark-comp(#ffa500);
}

.txt-orange-dark-1 {
	color: #1a1100;
}
.txt-orange-dark-1:hover {
	color: light-comp(#ffa500);
}

.txt-orange-light-2 {
	color: #ffedcc;
}
.txt-orange-light-2:hover {
	color: dark-comp(#ffa500);
}

.txt-orange-dark-2 {
	color: #332100;
}
.txt-orange-dark-2:hover {
	color: light-comp(#ffa500);
}

.txt-orange-light-3 {
	color: #ffe4b3;
}
.txt-orange-light-3:hover {
	color: dark-comp(#ffa500);
}

.txt-orange-dark-3 {
	color: #4d3200;
}
.txt-orange-dark-3:hover {
	color: light-comp(#ffa500);
}

.txt-orange-light-4 {
	color: #ffdb99;
}
.txt-orange-light-4:hover {
	color: dark-comp(#ffa500);
}

.txt-orange-dark-4 {
	color: #664200;
}
.txt-orange-dark-4:hover {
	color: light-comp(#ffa500);
}

.txt-orange-light-5 {
	color: #ffd280;
}
.txt-orange-light-5:hover {
	color: dark-comp(#ffa500);
}

.txt-orange-dark-5 {
	color: #805300;
}
.txt-orange-dark-5:hover {
	color: light-comp(#ffa500);
}

.txt-orange-light-6 {
	color: #ffc966;
}
.txt-orange-light-6:hover {
	color: dark-comp(#ffa500);
}

.txt-orange-dark-6 {
	color: #996300;
}
.txt-orange-dark-6:hover {
	color: light-comp(#ffa500);
}

.txt-orange-light-7 {
	color: #ffc04d;
}
.txt-orange-light-7:hover {
	color: dark-comp(#ffa500);
}

.txt-orange-dark-7 {
	color: #b37400;
}
.txt-orange-dark-7:hover {
	color: light-comp(#ffa500);
}

.txt-antique_white {
	color: #faebd7;
}

.bg-antique_white {
	background-color: #faebd7;
}

.txt-antique_white-light-1 {
	color: #fffdfb;
}
.txt-antique_white-light-1:hover {
	color: dark-comp(#faebd7);
}

.txt-antique_white-dark-1 {
	color: #191816;
}
.txt-antique_white-dark-1:hover {
	color: light-comp(#faebd7);
}

.txt-antique_white-light-2 {
	color: #fefbf7;
}
.txt-antique_white-light-2:hover {
	color: dark-comp(#faebd7);
}

.txt-antique_white-dark-2 {
	color: #322f2b;
}
.txt-antique_white-dark-2:hover {
	color: light-comp(#faebd7);
}

.txt-antique_white-light-3 {
	color: #fef9f3;
}
.txt-antique_white-light-3:hover {
	color: dark-comp(#faebd7);
}

.txt-antique_white-dark-3 {
	color: #4b4741;
}
.txt-antique_white-dark-3:hover {
	color: light-comp(#faebd7);
}

.txt-antique_white-light-4 {
	color: #fdf7ef;
}
.txt-antique_white-light-4:hover {
	color: dark-comp(#faebd7);
}

.txt-antique_white-dark-4 {
	color: #645e56;
}
.txt-antique_white-dark-4:hover {
	color: light-comp(#faebd7);
}

.txt-antique_white-light-5 {
	color: #fdf5eb;
}
.txt-antique_white-light-5:hover {
	color: dark-comp(#faebd7);
}

.txt-antique_white-dark-5 {
	color: #7d766c;
}
.txt-antique_white-dark-5:hover {
	color: light-comp(#faebd7);
}

.txt-antique_white-light-6 {
	color: #fcf3e7;
}
.txt-antique_white-light-6:hover {
	color: dark-comp(#faebd7);
}

.txt-antique_white-dark-6 {
	color: #968d81;
}
.txt-antique_white-dark-6:hover {
	color: light-comp(#faebd7);
}

.txt-antique_white-light-7 {
	color: #fcf1e3;
}
.txt-antique_white-light-7:hover {
	color: dark-comp(#faebd7);
}

.txt-antique_white-dark-7 {
	color: #afa597;
}
.txt-antique_white-dark-7:hover {
	color: light-comp(#faebd7);
}

.txt-light_grey {
	color: #d3d3d3;
}

.bg-light_grey {
	background-color: #d3d3d3;
}

.txt-light_grey-light-1 {
	color: #fbfbfb;
}
.txt-light_grey-light-1:hover {
	color: dark-comp(#d3d3d3);
}

.txt-light_grey-dark-1 {
	color: #151515;
}
.txt-light_grey-dark-1:hover {
	color: light-comp(#d3d3d3);
}

.txt-light_grey-light-2 {
	color: #f6f6f6;
}
.txt-light_grey-light-2:hover {
	color: dark-comp(#d3d3d3);
}

.txt-light_grey-dark-2 {
	color: #2a2a2a;
}
.txt-light_grey-dark-2:hover {
	color: light-comp(#d3d3d3);
}

.txt-light_grey-light-3 {
	color: #f2f2f2;
}
.txt-light_grey-light-3:hover {
	color: dark-comp(#d3d3d3);
}

.txt-light_grey-dark-3 {
	color: #3f3f3f;
}
.txt-light_grey-dark-3:hover {
	color: light-comp(#d3d3d3);
}

.txt-light_grey-light-4 {
	color: #ededed;
}
.txt-light_grey-light-4:hover {
	color: dark-comp(#d3d3d3);
}

.txt-light_grey-dark-4 {
	color: #545454;
}
.txt-light_grey-dark-4:hover {
	color: light-comp(#d3d3d3);
}

.txt-light_grey-light-5 {
	color: #e9e9e9;
}
.txt-light_grey-light-5:hover {
	color: dark-comp(#d3d3d3);
}

.txt-light_grey-dark-5 {
	color: #6a6a6a;
}
.txt-light_grey-dark-5:hover {
	color: light-comp(#d3d3d3);
}

.txt-light_grey-light-6 {
	color: #e5e5e5;
}
.txt-light_grey-light-6:hover {
	color: dark-comp(#d3d3d3);
}

.txt-light_grey-dark-6 {
	color: #7f7f7f;
}
.txt-light_grey-dark-6:hover {
	color: light-comp(#d3d3d3);
}

.txt-light_grey-light-7 {
	color: #e0e0e0;
}
.txt-light_grey-light-7:hover {
	color: dark-comp(#d3d3d3);
}

.txt-light_grey-dark-7 {
	color: #949494;
}
.txt-light_grey-dark-7:hover {
	color: light-comp(#d3d3d3);
}

.txt-goldenrod {
	color: #daa520;
}

.bg-goldenrod {
	background-color: #daa520;
}

.txt-goldenrod-light-1 {
	color: #fbf6e9;
}
.txt-goldenrod-light-1:hover {
	color: dark-comp(#daa520);
}

.txt-goldenrod-dark-1 {
	color: #161103;
}
.txt-goldenrod-dark-1:hover {
	color: light-comp(#daa520);
}

.txt-goldenrod-light-2 {
	color: #f8edd2;
}
.txt-goldenrod-light-2:hover {
	color: dark-comp(#daa520);
}

.txt-goldenrod-dark-2 {
	color: #2c2106;
}
.txt-goldenrod-dark-2:hover {
	color: light-comp(#daa520);
}

.txt-goldenrod-light-3 {
	color: #f4e4bc;
}
.txt-goldenrod-light-3:hover {
	color: dark-comp(#daa520);
}

.txt-goldenrod-dark-3 {
	color: #41320a;
}
.txt-goldenrod-dark-3:hover {
	color: light-comp(#daa520);
}

.txt-goldenrod-light-4 {
	color: #f0dba6;
}
.txt-goldenrod-light-4:hover {
	color: dark-comp(#daa520);
}

.txt-goldenrod-dark-4 {
	color: #57420d;
}
.txt-goldenrod-dark-4:hover {
	color: light-comp(#daa520);
}

.txt-goldenrod-light-5 {
	color: #edd290;
}
.txt-goldenrod-light-5:hover {
	color: dark-comp(#daa520);
}

.txt-goldenrod-dark-5 {
	color: #6d5310;
}
.txt-goldenrod-dark-5:hover {
	color: light-comp(#daa520);
}

.txt-goldenrod-light-6 {
	color: #e9c979;
}
.txt-goldenrod-light-6:hover {
	color: dark-comp(#daa520);
}

.txt-goldenrod-dark-6 {
	color: #836313;
}
.txt-goldenrod-dark-6:hover {
	color: light-comp(#daa520);
}

.txt-goldenrod-light-7 {
	color: #e5c063;
}
.txt-goldenrod-light-7:hover {
	color: dark-comp(#daa520);
}

.txt-goldenrod-dark-7 {
	color: #997416;
}
.txt-goldenrod-dark-7:hover {
	color: light-comp(#daa520);
}

.txt-tan {
	color: #d2b48c;
}

.bg-tan {
	background-color: #d2b48c;
}

.txt-tan-light-1 {
	color: #fbf8f4;
}
.txt-tan-light-1:hover {
	color: dark-comp(#d2b48c);
}

.txt-tan-dark-1 {
	color: #15120e;
}
.txt-tan-dark-1:hover {
	color: light-comp(#d2b48c);
}

.txt-tan-light-2 {
	color: #f6f0e8;
}
.txt-tan-light-2:hover {
	color: dark-comp(#d2b48c);
}

.txt-tan-dark-2 {
	color: #2a241c;
}
.txt-tan-dark-2:hover {
	color: light-comp(#d2b48c);
}

.txt-tan-light-3 {
	color: #f2e9dd;
}
.txt-tan-light-3:hover {
	color: dark-comp(#d2b48c);
}

.txt-tan-dark-3 {
	color: #3f362a;
}
.txt-tan-dark-3:hover {
	color: light-comp(#d2b48c);
}

.txt-tan-light-4 {
	color: #ede1d1;
}
.txt-tan-light-4:hover {
	color: dark-comp(#d2b48c);
}

.txt-tan-dark-4 {
	color: #544838;
}
.txt-tan-dark-4:hover {
	color: light-comp(#d2b48c);
}

.txt-tan-light-5 {
	color: #e9dac6;
}
.txt-tan-light-5:hover {
	color: dark-comp(#d2b48c);
}

.txt-tan-dark-5 {
	color: #695a46;
}
.txt-tan-dark-5:hover {
	color: light-comp(#d2b48c);
}

.txt-tan-light-6 {
	color: #e4d2ba;
}
.txt-tan-light-6:hover {
	color: dark-comp(#d2b48c);
}

.txt-tan-dark-6 {
	color: #7e6c54;
}
.txt-tan-dark-6:hover {
	color: light-comp(#d2b48c);
}

.txt-tan-light-7 {
	color: #e0cbaf;
}
.txt-tan-light-7:hover {
	color: dark-comp(#d2b48c);
}

.txt-tan-dark-7 {
	color: #937e62;
}
.txt-tan-dark-7:hover {
	color: light-comp(#d2b48c);
}

.txt-dark_goldenrod {
	color: #b8860b;
}

.bg-dark_goldenrod {
	background-color: #b8860b;
}

.txt-dark_goldenrod-light-1 {
	color: #f8f3e7;
}
.txt-dark_goldenrod-light-1:hover {
	color: dark-comp(#b8860b);
}

.txt-dark_goldenrod-dark-1 {
	color: #120d01;
}
.txt-dark_goldenrod-dark-1:hover {
	color: light-comp(#b8860b);
}

.txt-dark_goldenrod-light-2 {
	color: #f1e7ce;
}
.txt-dark_goldenrod-light-2:hover {
	color: dark-comp(#b8860b);
}

.txt-dark_goldenrod-dark-2 {
	color: #251b02;
}
.txt-dark_goldenrod-dark-2:hover {
	color: light-comp(#b8860b);
}

.txt-dark_goldenrod-light-3 {
	color: #eadbb6;
}
.txt-dark_goldenrod-light-3:hover {
	color: dark-comp(#b8860b);
}

.txt-dark_goldenrod-dark-3 {
	color: #372803;
}
.txt-dark_goldenrod-dark-3:hover {
	color: light-comp(#b8860b);
}

.txt-dark_goldenrod-light-4 {
	color: #e3cf9d;
}
.txt-dark_goldenrod-light-4:hover {
	color: dark-comp(#b8860b);
}

.txt-dark_goldenrod-dark-4 {
	color: #4a3604;
}
.txt-dark_goldenrod-dark-4:hover {
	color: light-comp(#b8860b);
}

.txt-dark_goldenrod-light-5 {
	color: #dcc385;
}
.txt-dark_goldenrod-light-5:hover {
	color: dark-comp(#b8860b);
}

.txt-dark_goldenrod-dark-5 {
	color: #5c4306;
}
.txt-dark_goldenrod-dark-5:hover {
	color: light-comp(#b8860b);
}

.txt-dark_goldenrod-light-6 {
	color: #d4b66d;
}
.txt-dark_goldenrod-light-6:hover {
	color: dark-comp(#b8860b);
}

.txt-dark_goldenrod-dark-6 {
	color: #6e5007;
}
.txt-dark_goldenrod-dark-6:hover {
	color: light-comp(#b8860b);
}

.txt-dark_goldenrod-light-7 {
	color: #cdaa54;
}
.txt-dark_goldenrod-light-7:hover {
	color: dark-comp(#b8860b);
}

.txt-dark_goldenrod-dark-7 {
	color: #815e08;
}
.txt-dark_goldenrod-dark-7:hover {
	color: light-comp(#b8860b);
}

.txt-yellow {
	color: #ffff00;
}

.bg-yellow {
	background-color: #ffff00;
}

.txt-yellow-light-1 {
	color: #ffffe6;
}
.txt-yellow-light-1:hover {
	color: dark-comp(#ffff00);
}

.txt-yellow-dark-1 {
	color: #1a1a00;
}
.txt-yellow-dark-1:hover {
	color: light-comp(#ffff00);
}

.txt-yellow-light-2 {
	color: #ffffcc;
}
.txt-yellow-light-2:hover {
	color: dark-comp(#ffff00);
}

.txt-yellow-dark-2 {
	color: #333300;
}
.txt-yellow-dark-2:hover {
	color: light-comp(#ffff00);
}

.txt-yellow-light-3 {
	color: #ffffb3;
}
.txt-yellow-light-3:hover {
	color: dark-comp(#ffff00);
}

.txt-yellow-dark-3 {
	color: #4d4d00;
}
.txt-yellow-dark-3:hover {
	color: light-comp(#ffff00);
}

.txt-yellow-light-4 {
	color: #ffff99;
}
.txt-yellow-light-4:hover {
	color: dark-comp(#ffff00);
}

.txt-yellow-dark-4 {
	color: #666600;
}
.txt-yellow-dark-4:hover {
	color: light-comp(#ffff00);
}

.txt-yellow-light-5 {
	color: #ffff80;
}
.txt-yellow-light-5:hover {
	color: dark-comp(#ffff00);
}

.txt-yellow-dark-5 {
	color: olive;
}
.txt-yellow-dark-5:hover {
	color: light-comp(#ffff00);
}

.txt-yellow-light-6 {
	color: #ffff66;
}
.txt-yellow-light-6:hover {
	color: dark-comp(#ffff00);
}

.txt-yellow-dark-6 {
	color: #999900;
}
.txt-yellow-dark-6:hover {
	color: light-comp(#ffff00);
}

.txt-yellow-light-7 {
	color: #ffff4d;
}
.txt-yellow-light-7:hover {
	color: dark-comp(#ffff00);
}

.txt-yellow-dark-7 {
	color: #b3b300;
}
.txt-yellow-dark-7:hover {
	color: light-comp(#ffff00);
}

.txt-orchid {
	color: #da70d6;
}

.bg-orchid {
	background-color: #da70d6;
}

.txt-orchid-light-1 {
	color: #fbf1fb;
}
.txt-orchid-light-1:hover {
	color: dark-comp(#da70d6);
}

.txt-orchid-dark-1 {
	color: #160b15;
}
.txt-orchid-dark-1:hover {
	color: light-comp(#da70d6);
}

.txt-orchid-light-2 {
	color: #f8e2f7;
}
.txt-orchid-light-2:hover {
	color: dark-comp(#da70d6);
}

.txt-orchid-dark-2 {
	color: #2c162b;
}
.txt-orchid-dark-2:hover {
	color: light-comp(#da70d6);
}

.txt-orchid-light-3 {
	color: #f4d4f3;
}
.txt-orchid-light-3:hover {
	color: dark-comp(#da70d6);
}

.txt-orchid-dark-3 {
	color: #412240;
}
.txt-orchid-dark-3:hover {
	color: light-comp(#da70d6);
}

.txt-orchid-light-4 {
	color: #f0c6ef;
}
.txt-orchid-light-4:hover {
	color: dark-comp(#da70d6);
}

.txt-orchid-dark-4 {
	color: #572d56;
}
.txt-orchid-dark-4:hover {
	color: light-comp(#da70d6);
}

.txt-orchid-light-5 {
	color: #edb8eb;
}
.txt-orchid-light-5:hover {
	color: dark-comp(#da70d6);
}

.txt-orchid-dark-5 {
	color: #6d386b;
}
.txt-orchid-dark-5:hover {
	color: light-comp(#da70d6);
}

.txt-orchid-light-6 {
	color: #e9a9e6;
}
.txt-orchid-light-6:hover {
	color: dark-comp(#da70d6);
}

.txt-orchid-dark-6 {
	color: #834380;
}
.txt-orchid-dark-6:hover {
	color: light-comp(#da70d6);
}

.txt-orchid-light-7 {
	color: #e59be2;
}
.txt-orchid-light-7:hover {
	color: dark-comp(#da70d6);
}

.txt-orchid-dark-7 {
	color: #994e96;
}
.txt-orchid-dark-7:hover {
	color: light-comp(#da70d6);
}

.txt-olive_drab {
	color: #6b8e23;
}

.bg-olive_drab {
	background-color: #6b8e23;
}

.txt-olive_drab-light-1 {
	color: #f0f4e9;
}
.txt-olive_drab-light-1:hover {
	color: dark-comp(#6b8e23);
}

.txt-olive_drab-dark-1 {
	color: #0b0e04;
}
.txt-olive_drab-dark-1:hover {
	color: light-comp(#6b8e23);
}

.txt-olive_drab-light-2 {
	color: #e1e8d3;
}
.txt-olive_drab-light-2:hover {
	color: dark-comp(#6b8e23);
}

.txt-olive_drab-dark-2 {
	color: #151c07;
}
.txt-olive_drab-dark-2:hover {
	color: light-comp(#6b8e23);
}

.txt-olive_drab-light-3 {
	color: #d3ddbd;
}
.txt-olive_drab-light-3:hover {
	color: dark-comp(#6b8e23);
}

.txt-olive_drab-dark-3 {
	color: #202b0b;
}
.txt-olive_drab-dark-3:hover {
	color: light-comp(#6b8e23);
}

.txt-olive_drab-light-4 {
	color: #c4d2a7;
}
.txt-olive_drab-light-4:hover {
	color: dark-comp(#6b8e23);
}

.txt-olive_drab-dark-4 {
	color: #2b390e;
}
.txt-olive_drab-dark-4:hover {
	color: light-comp(#6b8e23);
}

.txt-olive_drab-light-5 {
	color: #b5c791;
}
.txt-olive_drab-light-5:hover {
	color: dark-comp(#6b8e23);
}

.txt-olive_drab-dark-5 {
	color: #364712;
}
.txt-olive_drab-dark-5:hover {
	color: light-comp(#6b8e23);
}

.txt-olive_drab-light-6 {
	color: #a6bb7b;
}
.txt-olive_drab-light-6:hover {
	color: dark-comp(#6b8e23);
}

.txt-olive_drab-dark-6 {
	color: #405515;
}
.txt-olive_drab-dark-6:hover {
	color: light-comp(#6b8e23);
}

.txt-olive_drab-light-7 {
	color: #97b065;
}
.txt-olive_drab-light-7:hover {
	color: dark-comp(#6b8e23);
}

.txt-olive_drab-dark-7 {
	color: #4b6319;
}
.txt-olive_drab-dark-7:hover {
	color: light-comp(#6b8e23);
}

.txt-dark_khaki {
	color: #bdb76b;
}

.bg-dark_khaki {
	background-color: #bdb76b;
}

.txt-dark_khaki-light-1 {
	color: #f8f8f0;
}
.txt-dark_khaki-light-1:hover {
	color: dark-comp(#bdb76b);
}

.txt-dark_khaki-dark-1 {
	color: #13120b;
}
.txt-dark_khaki-dark-1:hover {
	color: light-comp(#bdb76b);
}

.txt-dark_khaki-light-2 {
	color: #f2f1e1;
}
.txt-dark_khaki-light-2:hover {
	color: dark-comp(#bdb76b);
}

.txt-dark_khaki-dark-2 {
	color: #262515;
}
.txt-dark_khaki-dark-2:hover {
	color: light-comp(#bdb76b);
}

.txt-dark_khaki-light-3 {
	color: #ebe9d3;
}
.txt-dark_khaki-light-3:hover {
	color: dark-comp(#bdb76b);
}

.txt-dark_khaki-dark-3 {
	color: #393720;
}
.txt-dark_khaki-dark-3:hover {
	color: light-comp(#bdb76b);
}

.txt-dark_khaki-light-4 {
	color: #e5e2c4;
}
.txt-dark_khaki-light-4:hover {
	color: dark-comp(#bdb76b);
}

.txt-dark_khaki-dark-4 {
	color: #4c492b;
}
.txt-dark_khaki-dark-4:hover {
	color: light-comp(#bdb76b);
}

.txt-dark_khaki-light-5 {
	color: #dedbb5;
}
.txt-dark_khaki-light-5:hover {
	color: dark-comp(#bdb76b);
}

.txt-dark_khaki-dark-5 {
	color: #5f5c36;
}
.txt-dark_khaki-dark-5:hover {
	color: light-comp(#bdb76b);
}

.txt-dark_khaki-light-6 {
	color: #d7d4a6;
}
.txt-dark_khaki-light-6:hover {
	color: dark-comp(#bdb76b);
}

.txt-dark_khaki-dark-6 {
	color: #716e40;
}
.txt-dark_khaki-dark-6:hover {
	color: light-comp(#bdb76b);
}

.txt-dark_khaki-light-7 {
	color: #d1cd97;
}
.txt-dark_khaki-light-7:hover {
	color: dark-comp(#bdb76b);
}

.txt-dark_khaki-dark-7 {
	color: #84804b;
}
.txt-dark_khaki-dark-7:hover {
	color: light-comp(#bdb76b);
}

.txt-medium_orchid {
	color: #ba55d3;
}

.bg-medium_orchid {
	background-color: #ba55d3;
}

.txt-medium_orchid-light-1 {
	color: #f8eefb;
}
.txt-medium_orchid-light-1:hover {
	color: dark-comp(#ba55d3);
}

.txt-medium_orchid-dark-1 {
	color: #130915;
}
.txt-medium_orchid-dark-1:hover {
	color: light-comp(#ba55d3);
}

.txt-medium_orchid-light-2 {
	color: #f1ddf6;
}
.txt-medium_orchid-light-2:hover {
	color: dark-comp(#ba55d3);
}

.txt-medium_orchid-dark-2 {
	color: #25112a;
}
.txt-medium_orchid-dark-2:hover {
	color: light-comp(#ba55d3);
}

.txt-medium_orchid-light-3 {
	color: #eaccf2;
}
.txt-medium_orchid-light-3:hover {
	color: dark-comp(#ba55d3);
}

.txt-medium_orchid-dark-3 {
	color: #381a3f;
}
.txt-medium_orchid-dark-3:hover {
	color: light-comp(#ba55d3);
}

.txt-medium_orchid-light-4 {
	color: #e3bbed;
}
.txt-medium_orchid-light-4:hover {
	color: dark-comp(#ba55d3);
}

.txt-medium_orchid-dark-4 {
	color: #4a2254;
}
.txt-medium_orchid-dark-4:hover {
	color: light-comp(#ba55d3);
}

.txt-medium_orchid-light-5 {
	color: #ddaae9;
}
.txt-medium_orchid-light-5:hover {
	color: dark-comp(#ba55d3);
}

.txt-medium_orchid-dark-5 {
	color: #5d2b6a;
}
.txt-medium_orchid-dark-5:hover {
	color: light-comp(#ba55d3);
}

.txt-medium_orchid-light-6 {
	color: #d699e5;
}
.txt-medium_orchid-light-6:hover {
	color: dark-comp(#ba55d3);
}

.txt-medium_orchid-dark-6 {
	color: #70337f;
}
.txt-medium_orchid-dark-6:hover {
	color: light-comp(#ba55d3);
}

.txt-medium_orchid-light-7 {
	color: #cf88e0;
}
.txt-medium_orchid-light-7:hover {
	color: dark-comp(#ba55d3);
}

.txt-medium_orchid-dark-7 {
	color: #823c94;
}
.txt-medium_orchid-dark-7:hover {
	color: light-comp(#ba55d3);
}

.txt-pale_green {
	color: #98fb98;
}

.bg-pale_green {
	background-color: #98fb98;
}

.txt-pale_green-light-1 {
	color: #f5fff5;
}
.txt-pale_green-light-1:hover {
	color: dark-comp(#98fb98);
}

.txt-pale_green-dark-1 {
	color: #0f190f;
}
.txt-pale_green-dark-1:hover {
	color: light-comp(#98fb98);
}

.txt-pale_green-light-2 {
	color: #eafeea;
}
.txt-pale_green-light-2:hover {
	color: dark-comp(#98fb98);
}

.txt-pale_green-dark-2 {
	color: #1e321e;
}
.txt-pale_green-dark-2:hover {
	color: light-comp(#98fb98);
}

.txt-pale_green-light-3 {
	color: #e0fee0;
}
.txt-pale_green-light-3:hover {
	color: dark-comp(#98fb98);
}

.txt-pale_green-dark-3 {
	color: #2e4b2e;
}
.txt-pale_green-dark-3:hover {
	color: light-comp(#98fb98);
}

.txt-pale_green-light-4 {
	color: #d6fdd6;
}
.txt-pale_green-light-4:hover {
	color: dark-comp(#98fb98);
}

.txt-pale_green-dark-4 {
	color: #3d643d;
}
.txt-pale_green-dark-4:hover {
	color: light-comp(#98fb98);
}

.txt-pale_green-light-5 {
	color: #ccfdcc;
}
.txt-pale_green-light-5:hover {
	color: dark-comp(#98fb98);
}

.txt-pale_green-dark-5 {
	color: #4c7e4c;
}
.txt-pale_green-dark-5:hover {
	color: light-comp(#98fb98);
}

.txt-pale_green-light-6 {
	color: #c1fdc1;
}
.txt-pale_green-light-6:hover {
	color: dark-comp(#98fb98);
}

.txt-pale_green-dark-6 {
	color: #5b975b;
}
.txt-pale_green-dark-6:hover {
	color: light-comp(#98fb98);
}

.txt-pale_green-light-7 {
	color: #b7fcb7;
}
.txt-pale_green-light-7:hover {
	color: dark-comp(#98fb98);
}

.txt-pale_green-dark-7 {
	color: #6ab06a;
}
.txt-pale_green-dark-7:hover {
	color: light-comp(#98fb98);
}

.txt-dark_slate_gray {
	color: #2f4f4f;
}

.bg-dark_slate_gray {
	background-color: #2f4f4f;
}

.txt-dark_slate_gray-light-1 {
	color: #eaeded;
}
.txt-dark_slate_gray-light-1:hover {
	color: dark-comp(#2f4f4f);
}

.txt-dark_slate_gray-dark-1 {
	color: #050808;
}
.txt-dark_slate_gray-dark-1:hover {
	color: light-comp(#2f4f4f);
}

.txt-dark_slate_gray-light-2 {
	color: #d5dcdc;
}
.txt-dark_slate_gray-light-2:hover {
	color: dark-comp(#2f4f4f);
}

.txt-dark_slate_gray-dark-2 {
	color: #091010;
}
.txt-dark_slate_gray-dark-2:hover {
	color: light-comp(#2f4f4f);
}

.txt-dark_slate_gray-light-3 {
	color: #c1caca;
}
.txt-dark_slate_gray-light-3:hover {
	color: dark-comp(#2f4f4f);
}

.txt-dark_slate_gray-dark-3 {
	color: #0e1818;
}
.txt-dark_slate_gray-dark-3:hover {
	color: light-comp(#2f4f4f);
}

.txt-dark_slate_gray-light-4 {
	color: #acb9b9;
}
.txt-dark_slate_gray-light-4:hover {
	color: dark-comp(#2f4f4f);
}

.txt-dark_slate_gray-dark-4 {
	color: #132020;
}
.txt-dark_slate_gray-dark-4:hover {
	color: light-comp(#2f4f4f);
}

.txt-dark_slate_gray-light-5 {
	color: #97a7a7;
}
.txt-dark_slate_gray-light-5:hover {
	color: dark-comp(#2f4f4f);
}

.txt-dark_slate_gray-dark-5 {
	color: #182828;
}
.txt-dark_slate_gray-dark-5:hover {
	color: light-comp(#2f4f4f);
}

.txt-dark_slate_gray-light-6 {
	color: #829595;
}
.txt-dark_slate_gray-light-6:hover {
	color: dark-comp(#2f4f4f);
}

.txt-dark_slate_gray-dark-6 {
	color: #1c2f2f;
}
.txt-dark_slate_gray-dark-6:hover {
	color: light-comp(#2f4f4f);
}

.txt-dark_slate_gray-light-7 {
	color: #6d8484;
}
.txt-dark_slate_gray-light-7:hover {
	color: dark-comp(#2f4f4f);
}

.txt-dark_slate_gray-dark-7 {
	color: #213737;
}
.txt-dark_slate_gray-dark-7:hover {
	color: light-comp(#2f4f4f);
}

.txt-dark_slate_blue {
	color: #483d8b;
}

.bg-dark_slate_blue {
	background-color: #483d8b;
}

.txt-dark_slate_blue-light-1 {
	color: #edecf3;
}
.txt-dark_slate_blue-light-1:hover {
	color: dark-comp(#483d8b);
}

.txt-dark_slate_blue-dark-1 {
	color: #07060e;
}
.txt-dark_slate_blue-dark-1:hover {
	color: light-comp(#483d8b);
}

.txt-dark_slate_blue-light-2 {
	color: #dad8e8;
}
.txt-dark_slate_blue-light-2:hover {
	color: dark-comp(#483d8b);
}

.txt-dark_slate_blue-dark-2 {
	color: #0e0c1c;
}
.txt-dark_slate_blue-dark-2:hover {
	color: light-comp(#483d8b);
}

.txt-dark_slate_blue-light-3 {
	color: #c8c5dc;
}
.txt-dark_slate_blue-light-3:hover {
	color: dark-comp(#483d8b);
}

.txt-dark_slate_blue-dark-3 {
	color: #16122a;
}
.txt-dark_slate_blue-dark-3:hover {
	color: light-comp(#483d8b);
}

.txt-dark_slate_blue-light-4 {
	color: #b6b1d1;
}
.txt-dark_slate_blue-light-4:hover {
	color: dark-comp(#483d8b);
}

.txt-dark_slate_blue-dark-4 {
	color: #1d1838;
}
.txt-dark_slate_blue-dark-4:hover {
	color: light-comp(#483d8b);
}

.txt-dark_slate_blue-light-5 {
	color: #a49ec5;
}
.txt-dark_slate_blue-light-5:hover {
	color: dark-comp(#483d8b);
}

.txt-dark_slate_blue-dark-5 {
	color: #241f46;
}
.txt-dark_slate_blue-dark-5:hover {
	color: light-comp(#483d8b);
}

.txt-dark_slate_blue-light-6 {
	color: #918bb9;
}
.txt-dark_slate_blue-light-6:hover {
	color: dark-comp(#483d8b);
}

.txt-dark_slate_blue-dark-6 {
	color: #2b2553;
}
.txt-dark_slate_blue-dark-6:hover {
	color: light-comp(#483d8b);
}

.txt-dark_slate_blue-light-7 {
	color: #7f77ae;
}
.txt-dark_slate_blue-light-7:hover {
	color: dark-comp(#483d8b);
}

.txt-dark_slate_blue-dark-7 {
	color: #322b61;
}
.txt-dark_slate_blue-dark-7:hover {
	color: light-comp(#483d8b);
}

.txt-sky_blue {
	color: #87ceeb;
}

.bg-sky_blue {
	background-color: #87ceeb;
}

.txt-sky_blue-light-1 {
	color: #f3fafd;
}
.txt-sky_blue-light-1:hover {
	color: dark-comp(#87ceeb);
}

.txt-sky_blue-dark-1 {
	color: #0e1518;
}
.txt-sky_blue-dark-1:hover {
	color: light-comp(#87ceeb);
}

.txt-sky_blue-light-2 {
	color: #e7f5fb;
}
.txt-sky_blue-light-2:hover {
	color: dark-comp(#87ceeb);
}

.txt-sky_blue-dark-2 {
	color: #1b292f;
}
.txt-sky_blue-dark-2:hover {
	color: light-comp(#87ceeb);
}

.txt-sky_blue-light-3 {
	color: #dbf0f9;
}
.txt-sky_blue-light-3:hover {
	color: dark-comp(#87ceeb);
}

.txt-sky_blue-dark-3 {
	color: #293e47;
}
.txt-sky_blue-dark-3:hover {
	color: light-comp(#87ceeb);
}

.txt-sky_blue-light-4 {
	color: #cfebf7;
}
.txt-sky_blue-light-4:hover {
	color: dark-comp(#87ceeb);
}

.txt-sky_blue-dark-4 {
	color: #36525e;
}
.txt-sky_blue-dark-4:hover {
	color: light-comp(#87ceeb);
}

.txt-sky_blue-light-5 {
	color: #c3e7f5;
}
.txt-sky_blue-light-5:hover {
	color: dark-comp(#87ceeb);
}

.txt-sky_blue-dark-5 {
	color: #446776;
}
.txt-sky_blue-dark-5:hover {
	color: light-comp(#87ceeb);
}

.txt-sky_blue-light-6 {
	color: #b7e2f3;
}
.txt-sky_blue-light-6:hover {
	color: dark-comp(#87ceeb);
}

.txt-sky_blue-dark-6 {
	color: #517c8d;
}
.txt-sky_blue-dark-6:hover {
	color: light-comp(#87ceeb);
}

.txt-sky_blue-light-7 {
	color: #abddf1;
}
.txt-sky_blue-light-7:hover {
	color: dark-comp(#87ceeb);
}

.txt-sky_blue-dark-7 {
	color: #5f90a5;
}
.txt-sky_blue-dark-7:hover {
	color: light-comp(#87ceeb);
}

.txt-spring_green {
	color: #00ff7f;
}

.bg-spring_green {
	background-color: #00ff7f;
}

.txt-spring_green-light-1 {
	color: #e6fff2;
}
.txt-spring_green-light-1:hover {
	color: dark-comp(#00ff7f);
}

.txt-spring_green-dark-1 {
	color: #001a0d;
}
.txt-spring_green-dark-1:hover {
	color: light-comp(#00ff7f);
}

.txt-spring_green-light-2 {
	color: #ccffe5;
}
.txt-spring_green-light-2:hover {
	color: dark-comp(#00ff7f);
}

.txt-spring_green-dark-2 {
	color: #003319;
}
.txt-spring_green-dark-2:hover {
	color: light-comp(#00ff7f);
}

.txt-spring_green-light-3 {
	color: #b3ffd9;
}
.txt-spring_green-light-3:hover {
	color: dark-comp(#00ff7f);
}

.txt-spring_green-dark-3 {
	color: #004d26;
}
.txt-spring_green-dark-3:hover {
	color: light-comp(#00ff7f);
}

.txt-spring_green-light-4 {
	color: #99ffcc;
}
.txt-spring_green-light-4:hover {
	color: dark-comp(#00ff7f);
}

.txt-spring_green-dark-4 {
	color: #006633;
}
.txt-spring_green-dark-4:hover {
	color: light-comp(#00ff7f);
}

.txt-spring_green-light-5 {
	color: #80ffbf;
}
.txt-spring_green-light-5:hover {
	color: dark-comp(#00ff7f);
}

.txt-spring_green-dark-5 {
	color: #008040;
}
.txt-spring_green-dark-5:hover {
	color: light-comp(#00ff7f);
}

.txt-spring_green-light-6 {
	color: #66ffb2;
}
.txt-spring_green-light-6:hover {
	color: dark-comp(#00ff7f);
}

.txt-spring_green-dark-6 {
	color: #00994c;
}
.txt-spring_green-dark-6:hover {
	color: light-comp(#00ff7f);
}

.txt-spring_green-light-7 {
	color: #4dffa5;
}
.txt-spring_green-light-7:hover {
	color: dark-comp(#00ff7f);
}

.txt-spring_green-dark-7 {
	color: #00b359;
}
.txt-spring_green-dark-7:hover {
	color: light-comp(#00ff7f);
}

.txt-saddle_brown {
	color: #8b4513;
}

.bg-saddle_brown {
	background-color: #8b4513;
}

.txt-saddle_brown-light-1 {
	color: #f3ece7;
}
.txt-saddle_brown-light-1:hover {
	color: dark-comp(#8b4513);
}

.txt-saddle_brown-dark-1 {
	color: #0e0702;
}
.txt-saddle_brown-dark-1:hover {
	color: light-comp(#8b4513);
}

.txt-saddle_brown-light-2 {
	color: #e8dad0;
}
.txt-saddle_brown-light-2:hover {
	color: dark-comp(#8b4513);
}

.txt-saddle_brown-dark-2 {
	color: #1c0e04;
}
.txt-saddle_brown-dark-2:hover {
	color: light-comp(#8b4513);
}

.txt-saddle_brown-light-3 {
	color: #dcc7b8;
}
.txt-saddle_brown-light-3:hover {
	color: dark-comp(#8b4513);
}

.txt-saddle_brown-dark-3 {
	color: #2a1506;
}
.txt-saddle_brown-dark-3:hover {
	color: light-comp(#8b4513);
}

.txt-saddle_brown-light-4 {
	color: #d1b5a1;
}
.txt-saddle_brown-light-4:hover {
	color: dark-comp(#8b4513);
}

.txt-saddle_brown-dark-4 {
	color: #381c08;
}
.txt-saddle_brown-dark-4:hover {
	color: light-comp(#8b4513);
}

.txt-saddle_brown-light-5 {
	color: #c5a289;
}
.txt-saddle_brown-light-5:hover {
	color: dark-comp(#8b4513);
}

.txt-saddle_brown-dark-5 {
	color: #46230a;
}
.txt-saddle_brown-dark-5:hover {
	color: light-comp(#8b4513);
}

.txt-saddle_brown-light-6 {
	color: #b98f71;
}
.txt-saddle_brown-light-6:hover {
	color: dark-comp(#8b4513);
}

.txt-saddle_brown-dark-6 {
	color: #53290b;
}
.txt-saddle_brown-dark-6:hover {
	color: light-comp(#8b4513);
}

.txt-saddle_brown-light-7 {
	color: #ae7d5a;
}
.txt-saddle_brown-light-7:hover {
	color: dark-comp(#8b4513);
}

.txt-saddle_brown-dark-7 {
	color: #61300d;
}
.txt-saddle_brown-dark-7:hover {
	color: light-comp(#8b4513);
}

.txt-medium_slate_blue {
	color: #7b68ee;
}

.bg-medium_slate_blue {
	background-color: #7b68ee;
}

.txt-medium_slate_blue-light-1 {
	color: #f2f0fd;
}
.txt-medium_slate_blue-light-1:hover {
	color: dark-comp(#7b68ee);
}

.txt-medium_slate_blue-dark-1 {
	color: #0c0a18;
}
.txt-medium_slate_blue-dark-1:hover {
	color: light-comp(#7b68ee);
}

.txt-medium_slate_blue-light-2 {
	color: #e5e1fc;
}
.txt-medium_slate_blue-light-2:hover {
	color: dark-comp(#7b68ee);
}

.txt-medium_slate_blue-dark-2 {
	color: #191530;
}
.txt-medium_slate_blue-dark-2:hover {
	color: light-comp(#7b68ee);
}

.txt-medium_slate_blue-light-3 {
	color: #d7d2fa;
}
.txt-medium_slate_blue-light-3:hover {
	color: dark-comp(#7b68ee);
}

.txt-medium_slate_blue-dark-3 {
	color: #251f47;
}
.txt-medium_slate_blue-dark-3:hover {
	color: light-comp(#7b68ee);
}

.txt-medium_slate_blue-light-4 {
	color: #cac3f8;
}
.txt-medium_slate_blue-light-4:hover {
	color: dark-comp(#7b68ee);
}

.txt-medium_slate_blue-dark-4 {
	color: #312a5f;
}
.txt-medium_slate_blue-dark-4:hover {
	color: light-comp(#7b68ee);
}

.txt-medium_slate_blue-light-5 {
	color: #bdb4f7;
}
.txt-medium_slate_blue-light-5:hover {
	color: dark-comp(#7b68ee);
}

.txt-medium_slate_blue-dark-5 {
	color: #3e3477;
}
.txt-medium_slate_blue-dark-5:hover {
	color: light-comp(#7b68ee);
}

.txt-medium_slate_blue-light-6 {
	color: #b0a4f5;
}
.txt-medium_slate_blue-light-6:hover {
	color: dark-comp(#7b68ee);
}

.txt-medium_slate_blue-dark-6 {
	color: #4a3e8f;
}
.txt-medium_slate_blue-dark-6:hover {
	color: light-comp(#7b68ee);
}

.txt-medium_slate_blue-light-7 {
	color: #a395f3;
}
.txt-medium_slate_blue-light-7:hover {
	color: dark-comp(#7b68ee);
}

.txt-medium_slate_blue-dark-7 {
	color: #5649a7;
}
.txt-medium_slate_blue-dark-7:hover {
	color: light-comp(#7b68ee);
}

.txt-deep_sky_blue {
	color: #00bfff;
}

.bg-deep_sky_blue {
	background-color: #00bfff;
}

.txt-deep_sky_blue-light-1 {
	color: #e6f9ff;
}
.txt-deep_sky_blue-light-1:hover {
	color: dark-comp(#00bfff);
}

.txt-deep_sky_blue-dark-1 {
	color: #00131a;
}
.txt-deep_sky_blue-dark-1:hover {
	color: light-comp(#00bfff);
}

.txt-deep_sky_blue-light-2 {
	color: #ccf2ff;
}
.txt-deep_sky_blue-light-2:hover {
	color: dark-comp(#00bfff);
}

.txt-deep_sky_blue-dark-2 {
	color: #002633;
}
.txt-deep_sky_blue-dark-2:hover {
	color: light-comp(#00bfff);
}

.txt-deep_sky_blue-light-3 {
	color: #b3ecff;
}
.txt-deep_sky_blue-light-3:hover {
	color: dark-comp(#00bfff);
}

.txt-deep_sky_blue-dark-3 {
	color: #00394d;
}
.txt-deep_sky_blue-dark-3:hover {
	color: light-comp(#00bfff);
}

.txt-deep_sky_blue-light-4 {
	color: #99e5ff;
}
.txt-deep_sky_blue-light-4:hover {
	color: dark-comp(#00bfff);
}

.txt-deep_sky_blue-dark-4 {
	color: #004c66;
}
.txt-deep_sky_blue-dark-4:hover {
	color: light-comp(#00bfff);
}

.txt-deep_sky_blue-light-5 {
	color: #80dfff;
}
.txt-deep_sky_blue-light-5:hover {
	color: dark-comp(#00bfff);
}

.txt-deep_sky_blue-dark-5 {
	color: #006080;
}
.txt-deep_sky_blue-dark-5:hover {
	color: light-comp(#00bfff);
}

.txt-deep_sky_blue-light-6 {
	color: #66d9ff;
}
.txt-deep_sky_blue-light-6:hover {
	color: dark-comp(#00bfff);
}

.txt-deep_sky_blue-dark-6 {
	color: #007399;
}
.txt-deep_sky_blue-dark-6:hover {
	color: light-comp(#00bfff);
}

.txt-deep_sky_blue-light-7 {
	color: #4dd2ff;
}
.txt-deep_sky_blue-light-7:hover {
	color: dark-comp(#00bfff);
}

.txt-deep_sky_blue-dark-7 {
	color: #0086b3;
}
.txt-deep_sky_blue-dark-7:hover {
	color: light-comp(#00bfff);
}

.txt-blanched_almond {
	color: #ffebcd;
}

.bg-blanched_almond {
	background-color: #ffebcd;
}

.txt-blanched_almond-light-1 {
	color: #fffdfa;
}
.txt-blanched_almond-light-1:hover {
	color: dark-comp(#ffebcd);
}

.txt-blanched_almond-dark-1 {
	color: #1a1815;
}
.txt-blanched_almond-dark-1:hover {
	color: light-comp(#ffebcd);
}

.txt-blanched_almond-light-2 {
	color: #fffbf5;
}
.txt-blanched_almond-light-2:hover {
	color: dark-comp(#ffebcd);
}

.txt-blanched_almond-dark-2 {
	color: #332f29;
}
.txt-blanched_almond-dark-2:hover {
	color: light-comp(#ffebcd);
}

.txt-blanched_almond-light-3 {
	color: #fff9f0;
}
.txt-blanched_almond-light-3:hover {
	color: dark-comp(#ffebcd);
}

.txt-blanched_almond-dark-3 {
	color: #4d473e;
}
.txt-blanched_almond-dark-3:hover {
	color: light-comp(#ffebcd);
}

.txt-blanched_almond-light-4 {
	color: #fff7eb;
}
.txt-blanched_almond-light-4:hover {
	color: dark-comp(#ffebcd);
}

.txt-blanched_almond-dark-4 {
	color: #665e52;
}
.txt-blanched_almond-dark-4:hover {
	color: light-comp(#ffebcd);
}

.txt-blanched_almond-light-5 {
	color: #fff5e6;
}
.txt-blanched_almond-light-5:hover {
	color: dark-comp(#ffebcd);
}

.txt-blanched_almond-dark-5 {
	color: #807667;
}
.txt-blanched_almond-dark-5:hover {
	color: light-comp(#ffebcd);
}

.txt-blanched_almond-light-6 {
	color: #fff3e1;
}
.txt-blanched_almond-light-6:hover {
	color: dark-comp(#ffebcd);
}

.txt-blanched_almond-dark-6 {
	color: #998d7b;
}
.txt-blanched_almond-dark-6:hover {
	color: light-comp(#ffebcd);
}

.txt-blanched_almond-light-7 {
	color: #fff1dc;
}
.txt-blanched_almond-light-7:hover {
	color: dark-comp(#ffebcd);
}

.txt-blanched_almond-dark-7 {
	color: #b3a590;
}
.txt-blanched_almond-dark-7:hover {
	color: light-comp(#ffebcd);
}

.txt-pale_violet_red {
	color: #db7093;
}

.bg-pale_violet_red {
	background-color: #db7093;
}

.txt-pale_violet_red-light-1 {
	color: #fbf1f4;
}
.txt-pale_violet_red-light-1:hover {
	color: dark-comp(#db7093);
}

.txt-pale_violet_red-dark-1 {
	color: #160b0f;
}
.txt-pale_violet_red-dark-1:hover {
	color: light-comp(#db7093);
}

.txt-pale_violet_red-light-2 {
	color: #f8e2e9;
}
.txt-pale_violet_red-light-2:hover {
	color: dark-comp(#db7093);
}

.txt-pale_violet_red-dark-2 {
	color: #2c161d;
}
.txt-pale_violet_red-dark-2:hover {
	color: light-comp(#db7093);
}

.txt-pale_violet_red-light-3 {
	color: #f4d4df;
}
.txt-pale_violet_red-light-3:hover {
	color: dark-comp(#db7093);
}

.txt-pale_violet_red-dark-3 {
	color: #42222c;
}
.txt-pale_violet_red-dark-3:hover {
	color: light-comp(#db7093);
}

.txt-pale_violet_red-light-4 {
	color: #f1c6d4;
}
.txt-pale_violet_red-light-4:hover {
	color: dark-comp(#db7093);
}

.txt-pale_violet_red-dark-4 {
	color: #582d3b;
}
.txt-pale_violet_red-dark-4:hover {
	color: light-comp(#db7093);
}

.txt-pale_violet_red-light-5 {
	color: #edb8c9;
}
.txt-pale_violet_red-light-5:hover {
	color: dark-comp(#db7093);
}

.txt-pale_violet_red-dark-5 {
	color: #6e384a;
}
.txt-pale_violet_red-dark-5:hover {
	color: light-comp(#db7093);
}

.txt-pale_violet_red-light-6 {
	color: #e9a9be;
}
.txt-pale_violet_red-light-6:hover {
	color: dark-comp(#db7093);
}

.txt-pale_violet_red-dark-6 {
	color: #834358;
}
.txt-pale_violet_red-dark-6:hover {
	color: light-comp(#db7093);
}

.txt-pale_violet_red-light-7 {
	color: #e69bb3;
}
.txt-pale_violet_red-light-7:hover {
	color: dark-comp(#db7093);
}

.txt-pale_violet_red-dark-7 {
	color: #994e67;
}
.txt-pale_violet_red-dark-7:hover {
	color: light-comp(#db7093);
}

.txt-deep_pink {
	color: #ff1493;
}

.bg-deep_pink {
	background-color: #ff1493;
}

.txt-deep_pink-light-1 {
	color: #ffe8f4;
}
.txt-deep_pink-light-1:hover {
	color: dark-comp(#ff1493);
}

.txt-deep_pink-dark-1 {
	color: #1a020f;
}
.txt-deep_pink-dark-1:hover {
	color: light-comp(#ff1493);
}

.txt-deep_pink-light-2 {
	color: #ffd0e9;
}
.txt-deep_pink-light-2:hover {
	color: dark-comp(#ff1493);
}

.txt-deep_pink-dark-2 {
	color: #33041d;
}
.txt-deep_pink-dark-2:hover {
	color: light-comp(#ff1493);
}

.txt-deep_pink-light-3 {
	color: #ffb9df;
}
.txt-deep_pink-light-3:hover {
	color: dark-comp(#ff1493);
}

.txt-deep_pink-dark-3 {
	color: #4d062c;
}
.txt-deep_pink-dark-3:hover {
	color: light-comp(#ff1493);
}

.txt-deep_pink-light-4 {
	color: #ffa1d4;
}
.txt-deep_pink-light-4:hover {
	color: dark-comp(#ff1493);
}

.txt-deep_pink-dark-4 {
	color: #66083b;
}
.txt-deep_pink-dark-4:hover {
	color: light-comp(#ff1493);
}

.txt-deep_pink-light-5 {
	color: #ff8ac9;
}
.txt-deep_pink-light-5:hover {
	color: dark-comp(#ff1493);
}

.txt-deep_pink-dark-5 {
	color: #800a4a;
}
.txt-deep_pink-dark-5:hover {
	color: light-comp(#ff1493);
}

.txt-deep_pink-light-6 {
	color: #ff72be;
}
.txt-deep_pink-light-6:hover {
	color: dark-comp(#ff1493);
}

.txt-deep_pink-dark-6 {
	color: #990c58;
}
.txt-deep_pink-dark-6:hover {
	color: light-comp(#ff1493);
}

.txt-deep_pink-light-7 {
	color: #ff5bb3;
}
.txt-deep_pink-light-7:hover {
	color: dark-comp(#ff1493);
}

.txt-deep_pink-dark-7 {
	color: #b30e67;
}
.txt-deep_pink-dark-7:hover {
	color: light-comp(#ff1493);
}

.txt-indigo {
	color: #4b0082;
}

.bg-indigo {
	background-color: #4b0082;
}

.txt-indigo-light-1 {
	color: #ede6f3;
}
.txt-indigo-light-1:hover {
	color: dark-comp(#4b0082);
}

.txt-indigo-dark-1 {
	color: #08000d;
}
.txt-indigo-dark-1:hover {
	color: light-comp(#4b0082);
}

.txt-indigo-light-2 {
	color: #dbcce6;
}
.txt-indigo-light-2:hover {
	color: dark-comp(#4b0082);
}

.txt-indigo-dark-2 {
	color: #0f001a;
}
.txt-indigo-dark-2:hover {
	color: light-comp(#4b0082);
}

.txt-indigo-light-3 {
	color: #c9b3da;
}
.txt-indigo-light-3:hover {
	color: dark-comp(#4b0082);
}

.txt-indigo-dark-3 {
	color: #170027;
}
.txt-indigo-dark-3:hover {
	color: light-comp(#4b0082);
}

.txt-indigo-light-4 {
	color: #b799cd;
}
.txt-indigo-light-4:hover {
	color: dark-comp(#4b0082);
}

.txt-indigo-dark-4 {
	color: #1e0034;
}
.txt-indigo-dark-4:hover {
	color: light-comp(#4b0082);
}

.txt-indigo-light-5 {
	color: #a580c1;
}
.txt-indigo-light-5:hover {
	color: dark-comp(#4b0082);
}

.txt-indigo-dark-5 {
	color: #260041;
}
.txt-indigo-dark-5:hover {
	color: light-comp(#4b0082);
}

.txt-indigo-light-6 {
	color: #9366b4;
}
.txt-indigo-light-6:hover {
	color: dark-comp(#4b0082);
}

.txt-indigo-dark-6 {
	color: #2d004e;
}
.txt-indigo-dark-6:hover {
	color: light-comp(#4b0082);
}

.txt-indigo-light-7 {
	color: #814da8;
}
.txt-indigo-light-7:hover {
	color: dark-comp(#4b0082);
}

.txt-indigo-dark-7 {
	color: #35005b;
}
.txt-indigo-dark-7:hover {
	color: light-comp(#4b0082);
}

.txt-dark_orange {
	color: #ff8c00;
}

.bg-dark_orange {
	background-color: #ff8c00;
}

.txt-dark_orange-light-1 {
	color: #fff4e6;
}
.txt-dark_orange-light-1:hover {
	color: dark-comp(#ff8c00);
}

.txt-dark_orange-dark-1 {
	color: #1a0e00;
}
.txt-dark_orange-dark-1:hover {
	color: light-comp(#ff8c00);
}

.txt-dark_orange-light-2 {
	color: #ffe8cc;
}
.txt-dark_orange-light-2:hover {
	color: dark-comp(#ff8c00);
}

.txt-dark_orange-dark-2 {
	color: #331c00;
}
.txt-dark_orange-dark-2:hover {
	color: light-comp(#ff8c00);
}

.txt-dark_orange-light-3 {
	color: #ffddb3;
}
.txt-dark_orange-light-3:hover {
	color: dark-comp(#ff8c00);
}

.txt-dark_orange-dark-3 {
	color: #4d2a00;
}
.txt-dark_orange-dark-3:hover {
	color: light-comp(#ff8c00);
}

.txt-dark_orange-light-4 {
	color: #ffd199;
}
.txt-dark_orange-light-4:hover {
	color: dark-comp(#ff8c00);
}

.txt-dark_orange-dark-4 {
	color: #663800;
}
.txt-dark_orange-dark-4:hover {
	color: light-comp(#ff8c00);
}

.txt-dark_orange-light-5 {
	color: #ffc680;
}
.txt-dark_orange-light-5:hover {
	color: dark-comp(#ff8c00);
}

.txt-dark_orange-dark-5 {
	color: #804600;
}
.txt-dark_orange-dark-5:hover {
	color: light-comp(#ff8c00);
}

.txt-dark_orange-light-6 {
	color: #ffba66;
}
.txt-dark_orange-light-6:hover {
	color: dark-comp(#ff8c00);
}

.txt-dark_orange-dark-6 {
	color: #995400;
}
.txt-dark_orange-dark-6:hover {
	color: light-comp(#ff8c00);
}

.txt-dark_orange-light-7 {
	color: #ffaf4d;
}
.txt-dark_orange-light-7:hover {
	color: dark-comp(#ff8c00);
}

.txt-dark_orange-dark-7 {
	color: #b36200;
}
.txt-dark_orange-dark-7:hover {
	color: light-comp(#ff8c00);
}

.txt-alice_blue {
	color: #f0f8ff;
}

.bg-alice_blue {
	background-color: #f0f8ff;
}

.txt-alice_blue-light-1 {
	color: #fefeff;
}
.txt-alice_blue-light-1:hover {
	color: dark-comp(#f0f8ff);
}

.txt-alice_blue-dark-1 {
	color: #18191a;
}
.txt-alice_blue-dark-1:hover {
	color: light-comp(#f0f8ff);
}

.txt-alice_blue-light-2 {
	color: #fcfeff;
}
.txt-alice_blue-light-2:hover {
	color: dark-comp(#f0f8ff);
}

.txt-alice_blue-dark-2 {
	color: #303233;
}
.txt-alice_blue-dark-2:hover {
	color: light-comp(#f0f8ff);
}

.txt-alice_blue-light-3 {
	color: #fbfdff;
}
.txt-alice_blue-light-3:hover {
	color: dark-comp(#f0f8ff);
}

.txt-alice_blue-dark-3 {
	color: #484a4d;
}
.txt-alice_blue-dark-3:hover {
	color: light-comp(#f0f8ff);
}

.txt-alice_blue-light-4 {
	color: #f9fcff;
}
.txt-alice_blue-light-4:hover {
	color: dark-comp(#f0f8ff);
}

.txt-alice_blue-dark-4 {
	color: #606366;
}
.txt-alice_blue-dark-4:hover {
	color: light-comp(#f0f8ff);
}

.txt-alice_blue-light-5 {
	color: #f8fcff;
}
.txt-alice_blue-light-5:hover {
	color: dark-comp(#f0f8ff);
}

.txt-alice_blue-dark-5 {
	color: #787c80;
}
.txt-alice_blue-dark-5:hover {
	color: light-comp(#f0f8ff);
}

.txt-alice_blue-light-6 {
	color: #f6fbff;
}
.txt-alice_blue-light-6:hover {
	color: dark-comp(#f0f8ff);
}

.txt-alice_blue-dark-6 {
	color: #909599;
}
.txt-alice_blue-dark-6:hover {
	color: light-comp(#f0f8ff);
}

.txt-alice_blue-light-7 {
	color: #f5faff;
}
.txt-alice_blue-light-7:hover {
	color: dark-comp(#f0f8ff);
}

.txt-alice_blue-dark-7 {
	color: #a8aeb3;
}
.txt-alice_blue-dark-7:hover {
	color: light-comp(#f0f8ff);
}

.txt-forest_green {
	color: #228b22;
}

.bg-forest_green {
	background-color: #228b22;
}

.txt-forest_green-light-1 {
	color: #e9f3e9;
}
.txt-forest_green-light-1:hover {
	color: dark-comp(#228b22);
}

.txt-forest_green-dark-1 {
	color: #030e03;
}
.txt-forest_green-dark-1:hover {
	color: light-comp(#228b22);
}

.txt-forest_green-light-2 {
	color: #d3e8d3;
}
.txt-forest_green-light-2:hover {
	color: dark-comp(#228b22);
}

.txt-forest_green-dark-2 {
	color: #071c07;
}
.txt-forest_green-dark-2:hover {
	color: light-comp(#228b22);
}

.txt-forest_green-light-3 {
	color: #bddcbd;
}
.txt-forest_green-light-3:hover {
	color: dark-comp(#228b22);
}

.txt-forest_green-dark-3 {
	color: #0a2a0a;
}
.txt-forest_green-dark-3:hover {
	color: light-comp(#228b22);
}

.txt-forest_green-light-4 {
	color: #a7d1a7;
}
.txt-forest_green-light-4:hover {
	color: dark-comp(#228b22);
}

.txt-forest_green-dark-4 {
	color: #0e380e;
}
.txt-forest_green-dark-4:hover {
	color: light-comp(#228b22);
}

.txt-forest_green-light-5 {
	color: #91c591;
}
.txt-forest_green-light-5:hover {
	color: dark-comp(#228b22);
}

.txt-forest_green-dark-5 {
	color: #114611;
}
.txt-forest_green-dark-5:hover {
	color: light-comp(#228b22);
}

.txt-forest_green-light-6 {
	color: #7ab97a;
}
.txt-forest_green-light-6:hover {
	color: dark-comp(#228b22);
}

.txt-forest_green-dark-6 {
	color: #145314;
}
.txt-forest_green-dark-6:hover {
	color: light-comp(#228b22);
}

.txt-forest_green-light-7 {
	color: #64ae64;
}
.txt-forest_green-light-7:hover {
	color: dark-comp(#228b22);
}

.txt-forest_green-dark-7 {
	color: #186118;
}
.txt-forest_green-dark-7:hover {
	color: light-comp(#228b22);
}

.txt-dark_violet {
	color: #9400d3;
}

.bg-dark_violet {
	background-color: #9400d3;
}

.txt-dark_violet-light-1 {
	color: #f4e6fb;
}
.txt-dark_violet-light-1:hover {
	color: dark-comp(#9400d3);
}

.txt-dark_violet-dark-1 {
	color: #0f0015;
}
.txt-dark_violet-dark-1:hover {
	color: light-comp(#9400d3);
}

.txt-dark_violet-light-2 {
	color: #eaccf6;
}
.txt-dark_violet-light-2:hover {
	color: dark-comp(#9400d3);
}

.txt-dark_violet-dark-2 {
	color: #1e002a;
}
.txt-dark_violet-dark-2:hover {
	color: light-comp(#9400d3);
}

.txt-dark_violet-light-3 {
	color: #dfb3f2;
}
.txt-dark_violet-light-3:hover {
	color: dark-comp(#9400d3);
}

.txt-dark_violet-dark-3 {
	color: #2c003f;
}
.txt-dark_violet-dark-3:hover {
	color: light-comp(#9400d3);
}

.txt-dark_violet-light-4 {
	color: #d499ed;
}
.txt-dark_violet-light-4:hover {
	color: dark-comp(#9400d3);
}

.txt-dark_violet-dark-4 {
	color: #3b0054;
}
.txt-dark_violet-dark-4:hover {
	color: light-comp(#9400d3);
}

.txt-dark_violet-light-5 {
	color: #ca80e9;
}
.txt-dark_violet-light-5:hover {
	color: dark-comp(#9400d3);
}

.txt-dark_violet-dark-5 {
	color: #4a006a;
}
.txt-dark_violet-dark-5:hover {
	color: light-comp(#9400d3);
}

.txt-dark_violet-light-6 {
	color: #bf66e5;
}
.txt-dark_violet-light-6:hover {
	color: dark-comp(#9400d3);
}

.txt-dark_violet-dark-6 {
	color: #59007f;
}
.txt-dark_violet-dark-6:hover {
	color: light-comp(#9400d3);
}

.txt-dark_violet-light-7 {
	color: #b44de0;
}
.txt-dark_violet-light-7:hover {
	color: dark-comp(#9400d3);
}

.txt-dark_violet-dark-7 {
	color: #680094;
}
.txt-dark_violet-dark-7:hover {
	color: light-comp(#9400d3);
}

.txt-peach_puff {
	color: #ffdab9;
}

.bg-peach_puff {
	background-color: #ffdab9;
}

.txt-peach_puff-light-1 {
	color: #fffbf8;
}
.txt-peach_puff-light-1:hover {
	color: dark-comp(#ffdab9);
}

.txt-peach_puff-dark-1 {
	color: #1a1613;
}
.txt-peach_puff-dark-1:hover {
	color: light-comp(#ffdab9);
}

.txt-peach_puff-light-2 {
	color: #fff8f1;
}
.txt-peach_puff-light-2:hover {
	color: dark-comp(#ffdab9);
}

.txt-peach_puff-dark-2 {
	color: #332c25;
}
.txt-peach_puff-dark-2:hover {
	color: light-comp(#ffdab9);
}

.txt-peach_puff-light-3 {
	color: #fff4ea;
}
.txt-peach_puff-light-3:hover {
	color: dark-comp(#ffdab9);
}

.txt-peach_puff-dark-3 {
	color: #4d4138;
}
.txt-peach_puff-dark-3:hover {
	color: light-comp(#ffdab9);
}

.txt-peach_puff-light-4 {
	color: #fff0e3;
}
.txt-peach_puff-light-4:hover {
	color: dark-comp(#ffdab9);
}

.txt-peach_puff-dark-4 {
	color: #66574a;
}
.txt-peach_puff-dark-4:hover {
	color: light-comp(#ffdab9);
}

.txt-peach_puff-light-5 {
	color: #ffeddc;
}
.txt-peach_puff-light-5:hover {
	color: dark-comp(#ffdab9);
}

.txt-peach_puff-dark-5 {
	color: #806d5d;
}
.txt-peach_puff-dark-5:hover {
	color: light-comp(#ffdab9);
}

.txt-peach_puff-light-6 {
	color: #ffe9d5;
}
.txt-peach_puff-light-6:hover {
	color: dark-comp(#ffdab9);
}

.txt-peach_puff-dark-6 {
	color: #99836f;
}
.txt-peach_puff-dark-6:hover {
	color: light-comp(#ffdab9);
}

.txt-peach_puff-light-7 {
	color: #ffe5ce;
}
.txt-peach_puff-light-7:hover {
	color: dark-comp(#ffdab9);
}

.txt-peach_puff-dark-7 {
	color: #b39982;
}
.txt-peach_puff-dark-7:hover {
	color: light-comp(#ffdab9);
}

.txt-lime {
	color: #00ff00;
}

.bg-lime {
	background-color: #00ff00;
}

.txt-lime-light-1 {
	color: #e6ffe6;
}
.txt-lime-light-1:hover {
	color: dark-comp(#00ff00);
}

.txt-lime-dark-1 {
	color: #001a00;
}
.txt-lime-dark-1:hover {
	color: light-comp(#00ff00);
}

.txt-lime-light-2 {
	color: #ccffcc;
}
.txt-lime-light-2:hover {
	color: dark-comp(#00ff00);
}

.txt-lime-dark-2 {
	color: #003300;
}
.txt-lime-dark-2:hover {
	color: light-comp(#00ff00);
}

.txt-lime-light-3 {
	color: #b3ffb3;
}
.txt-lime-light-3:hover {
	color: dark-comp(#00ff00);
}

.txt-lime-dark-3 {
	color: #004d00;
}
.txt-lime-dark-3:hover {
	color: light-comp(#00ff00);
}

.txt-lime-light-4 {
	color: #99ff99;
}
.txt-lime-light-4:hover {
	color: dark-comp(#00ff00);
}

.txt-lime-dark-4 {
	color: #006600;
}
.txt-lime-dark-4:hover {
	color: light-comp(#00ff00);
}

.txt-lime-light-5 {
	color: #80ff80;
}
.txt-lime-light-5:hover {
	color: dark-comp(#00ff00);
}

.txt-lime-dark-5 {
	color: green;
}
.txt-lime-dark-5:hover {
	color: light-comp(#00ff00);
}

.txt-lime-light-6 {
	color: #66ff66;
}
.txt-lime-light-6:hover {
	color: dark-comp(#00ff00);
}

.txt-lime-dark-6 {
	color: #009900;
}
.txt-lime-dark-6:hover {
	color: light-comp(#00ff00);
}

.txt-lime-light-7 {
	color: #4dff4d;
}
.txt-lime-light-7:hover {
	color: dark-comp(#00ff00);
}

.txt-lime-dark-7 {
	color: #00b300;
}
.txt-lime-dark-7:hover {
	color: light-comp(#00ff00);
}

.txt-light_gray {
	color: lightgray;
}

.bg-light_gray {
	background-color: lightgray;
}

.txt-light_gray-light-1 {
	color: #fbfbfb;
}
.txt-light_gray-light-1:hover {
	color: dark-comp(lightgray);
}

.txt-light_gray-dark-1 {
	color: #151515;
}
.txt-light_gray-dark-1:hover {
	color: light-comp(lightgray);
}

.txt-light_gray-light-2 {
	color: #f6f6f6;
}
.txt-light_gray-light-2:hover {
	color: dark-comp(lightgray);
}

.txt-light_gray-dark-2 {
	color: #2a2a2a;
}
.txt-light_gray-dark-2:hover {
	color: light-comp(lightgray);
}

.txt-light_gray-light-3 {
	color: #f2f2f2;
}
.txt-light_gray-light-3:hover {
	color: dark-comp(lightgray);
}

.txt-light_gray-dark-3 {
	color: #3f3f3f;
}
.txt-light_gray-dark-3:hover {
	color: light-comp(lightgray);
}

.txt-light_gray-light-4 {
	color: #ededed;
}
.txt-light_gray-light-4:hover {
	color: dark-comp(lightgray);
}

.txt-light_gray-dark-4 {
	color: #545454;
}
.txt-light_gray-dark-4:hover {
	color: light-comp(lightgray);
}

.txt-light_gray-light-5 {
	color: #e9e9e9;
}
.txt-light_gray-light-5:hover {
	color: dark-comp(lightgray);
}

.txt-light_gray-dark-5 {
	color: #6a6a6a;
}
.txt-light_gray-dark-5:hover {
	color: light-comp(lightgray);
}

.txt-light_gray-light-6 {
	color: #e5e5e5;
}
.txt-light_gray-light-6:hover {
	color: dark-comp(lightgray);
}

.txt-light_gray-dark-6 {
	color: #7f7f7f;
}
.txt-light_gray-dark-6:hover {
	color: light-comp(lightgray);
}

.txt-light_gray-light-7 {
	color: #e0e0e0;
}
.txt-light_gray-light-7:hover {
	color: dark-comp(lightgray);
}

.txt-light_gray-dark-7 {
	color: #949494;
}
.txt-light_gray-dark-7:hover {
	color: light-comp(lightgray);
}

.txt-cool_gray {
	color: #d4d4d8;
}

.bg-cool_gray {
	background-color: #d4d4d8;
}

.txt-cool_gray-light-1 {
	color: #fbfbfb;
}
.txt-cool_gray-light-1:hover {
	color: dark-comp(#d4d4d8);
}

.txt-cool_gray-dark-1 {
	color: #151516;
}
.txt-cool_gray-dark-1:hover {
	color: light-comp(#d4d4d8);
}

.txt-cool_gray-light-2 {
	color: #f6f6f7;
}
.txt-cool_gray-light-2:hover {
	color: dark-comp(#d4d4d8);
}

.txt-cool_gray-dark-2 {
	color: #2a2a2b;
}
.txt-cool_gray-dark-2:hover {
	color: light-comp(#d4d4d8);
}

.txt-cool_gray-light-3 {
	color: #f2f2f3;
}
.txt-cool_gray-light-3:hover {
	color: dark-comp(#d4d4d8);
}

.txt-cool_gray-dark-3 {
	color: #404041;
}
.txt-cool_gray-dark-3:hover {
	color: light-comp(#d4d4d8);
}

.txt-cool_gray-light-4 {
	color: #eeeeef;
}
.txt-cool_gray-light-4:hover {
	color: dark-comp(#d4d4d8);
}

.txt-cool_gray-dark-4 {
	color: #555556;
}
.txt-cool_gray-dark-4:hover {
	color: light-comp(#d4d4d8);
}

.txt-cool_gray-light-5 {
	color: #eaeaec;
}
.txt-cool_gray-light-5:hover {
	color: dark-comp(#d4d4d8);
}

.txt-cool_gray-dark-5 {
	color: #6a6a6c;
}
.txt-cool_gray-dark-5:hover {
	color: light-comp(#d4d4d8);
}

.txt-cool_gray-light-6 {
	color: #e5e5e8;
}
.txt-cool_gray-light-6:hover {
	color: dark-comp(#d4d4d8);
}

.txt-cool_gray-dark-6 {
	color: #7f7f82;
}
.txt-cool_gray-dark-6:hover {
	color: light-comp(#d4d4d8);
}

.txt-cool_gray-light-7 {
	color: #e1e1e4;
}
.txt-cool_gray-light-7:hover {
	color: dark-comp(#d4d4d8);
}

.txt-cool_gray-dark-7 {
	color: #949497;
}
.txt-cool_gray-dark-7:hover {
	color: light-comp(#d4d4d8);
}

html,
body,
#root {
	/* height: 100% !important; */
}

body {
	/* font-family: 'Amaranth' !important; */
	font-family: 'Cairo' !important;
	/* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
	/* font-family: Montserrat, sans-serif; */
	font-size: 20px;
	font-weight: 700;
}

.hand {
	cursor: pointer;
}

.login_form {
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	z-index: 10000001;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.login_form .form_inner {
	background-color: white;
	width: 85%;
	max-width: 400px;
	box-shadow: 0 5px 9px rgba(0, 0, 0, 0.22), 0 5px 6px rgba(0, 0, 0, 0.44);
	border-radius: 10px;
	padding: 10px;
}

.pointer {
	cursor: pointer;
}

.t-x5 {
	position: relative !important;
	top: -50px !important;
	z-index: 100 !important;
}

@-webkit-keyframes enter {
	0% {
		opacity: 0;
		top: -10px;
	}
	5% {
		opacity: 1;
		top: 0px;
	}
	50.9% {
		opacity: 1;
		top: 0px;
	}
	55.9% {
		opacity: 0;
		top: 10px;
	}
}
@keyframes enter {
	0% {
		opacity: 0;
		top: -10px;
	}
	5% {
		opacity: 1;
		top: 0px;
	}
	50.9% {
		opacity: 1;
		top: 0px;
	}
	55.9% {
		opacity: 0;
		top: 10px;
	}
}
.loading {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border: 4px solid green;
	position: fixed;
	left: 0%;
	top: 0%;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10000002000;
}
.loading .square {
	background: white;
	width: 15px;
	height: 15px;
	float: left;
	top: -10px;
	margin-right: 5px;
	margin-top: 5px;
	position: relative;
	opacity: 0;
	-webkit-animation: enter 6s infinite;
	animation: enter 6s infinite;
}
.loading .enter {
	top: 0px;
	opacity: 1;
}
.loading .square:nth-child(1) {
	-webkit-animation-delay: 1.8s;
	animation-delay: 1.8s;
}
.loading .square:nth-child(2) {
	-webkit-animation-delay: 2.1s;
	animation-delay: 2.1s;
}
.loading .square:nth-child(3) {
	-webkit-animation-delay: 2.4s;
	animation-delay: 2.4s;
	background: #fdc96f;
}
.loading .square:nth-child(4) {
	-webkit-animation-delay: 0.9s;
	animation-delay: 0.9s;
}
.loading .square:nth-child(5) {
	-webkit-animation-delay: 1.2s;
	animation-delay: 1.2s;
}
.loading .square:nth-child(6) {
	-webkit-animation-delay: 1.5s;
	animation-delay: 1.5s;
}
.loading .square:nth-child(8) {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}
.loading .square:nth-child(9) {
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}
.loading .clear {
	clear: both;
}
.loading .last {
	margin-right: 0;
}

.full_width {
	background-image: url('./assets/imgs/bg-02-dark.jpg');
	background-size: cover;
	background-position: center;
	padding: 100px;
}

.teacher_box {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.32), 0 3px 4px rgba(0, 0, 0, 0.64);
	margin-bottom: 15px;
	padding: 10px;
	border-radius: 5px;
	border-top: 16px solid white;
}
.teacher_box:hover {
	border-top: 16px solid #ffa500;
}
.teacher_box:hover button {
	background-color: #ffa500;
}
.teacher_box .teacher {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 20px;
}
.teacher_box .teacher .avatar {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	overflow: hidden;
	border: 5px solid white;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.32), 0 3px 4px rgba(0, 0, 0, 0.64);
}
.teacher_box .teacher .avatar img {
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
}
.teacher_box .teacher .about {
	flex: 5;
}
.teacher_box .courses {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 20px 0;
	width: 100%;
	overflow-x: auto;
	flex-wrap: nowrap;
}
.teacher_box .courses .courses_inner {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.teacher_box .courses .courses_inner span {
	background-color: #ffa500;
	color: white;
	margin-right: 5px;
	padding: 5px 15px;
	border-radius: 20px;
}
.teacher_box .btns {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin-top: 10px;
}
.teacher_box .btns button {
	margin-right: 10px;
}

/* Parent Categories*/
.parent_container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}
.parent_container .parent_box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 6px solid #ff6347;
	background: white;
	color: #ff6347;
	font-size: 1.1rem;
	border-radius: 20px;
	width: 250px;
	height: 150px;
	text-align: center;
	text-transform: uppercase;
	margin: 10px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.52), 0 1px 2px rgba(0, 0, 0, 0.84);
	cursor: pointer;
}
.parent_container .parent_box svg {
	font-size: 1.5em;
	margin: 25px;
}
.parent_container .parent_box span {
	font-weight: 500;
}

/* Categories Page */
.child_container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.child_box {
	border: 6px solid #ff6347;
	background-color: white;
	color: #ff6347;
	font-weight: 900;
	font-size: 1.3rem;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.32), 0 3px 4px rgba(0, 0, 0, 0.64);
	margin: 10px;
	border-radius: 15px;
	width: 150px;
	height: 150px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
}

/* Courses Page */
.courses_container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
}
.courses_container .course_box {
	border: 5px solid #eee;
	border-radius: 50% 45px 50% 50%;
	width: 250px;
	height: 250px;
	background-color: #ff6347;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}
.courses_container .course_box span {
	width: 70%;
	height: 70%;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	background-color: white;
	align-items: center;
	text-align: center;
	color: #ff6347;
	font-size: 1.5rem;
}

.avatar {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	overflow: hidden;
	border: 5px solid white;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.32), 0 3px 4px rgba(0, 0, 0, 0.64);
}
.avatar img {
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
} /*# sourceMappingURL=App.css.map */
