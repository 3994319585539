$ghost_white: #f8f8ff;
$turquoise: #40e0d0;
$violet: #ee82ee;
$gainsboro: #dcdcdc;
$light_goldenrod_yellow: #fafad2;
$peru: #cd853f;
$medium_aquamarine: #66cdaa;
$cadet_blue: #5f9ea0;
$dark_sea_green: #8fbc8f;
$medium_purple: #9370db;
$tomato: #ff6347;
$red: #ff0000;
$pale_red: #de9898;
$aqua: #00ffff;
$gold: #ffd700;
$chocolate: #d2691e;
$light_yellow: #ffffe0;
$khaki: #f0e68c;
$yellow_green: #9acd32;
$coral: #ff7f50;
$snow: #fffafa;
$light_salmon: #ffa07a;
$light_sky_blue: #87cefa;
$plum: #dda0dd;
$maroon: #800000;
$cornflower_blue: #6495ed;
$cornsilk: #fff8dc;
$salmon: #fa8072;
$purple: #800080;
$slate_gray: #708090;
$orange: #ffa500;
$antique_white: #faebd7;
$light_grey: #e3e3e3;
$goldenrod: #daa520;
$tan: #d2b48c;
$dark_goldenrod: #b8860b;
$yellow: #ffff00;
$orchid: #da70d6;
$olive_drab: #6b8e23;
$dark_khaki: #bdb76b;
$medium_orchid: #ba55d3;
$pale_green: #98fb98;
$dark_slate_gray: #2f4f4f;
$light_dim_gray: #939393;
$dark_slate_blue: #483d8b;
$sky_blue: #87ceeb;
$spring_green: #00ff7f;
$saddle_brown: #8b4513;
$medium_slate_blue: #7b68ee;
$deep_sky_blue: #00bfff;
$blanched_almond: #ffebcd;
$pale_violet_red: #db7093;
$deep_pink: #ff1493;
$indigo: #4b0082;
$dark_orange: #ff8c00;
$alice_blue: #f0f8ff;
$forest_green: #228b22;
$dark_violet: #9400d3;
$peach_puff: #ffdab9;
$lime: #00ff00;
$white: white;
$black: black;
$gray: gray;
$dark_gray: #333;
$light_gray: lightgray;
$cool_gray: #d4d4d8;
$fancy_gray: #eeeff3;

$colors_arr: (
	'ghost_white': $ghost_white,
	'turquoise': $turquoise,
	'violet': $violet,
	'gainsboro': $gainsboro,
	'light_goldenrod_yellow': $light_goldenrod_yellow,
	'peru': $peru,
	'medium_aquamarine': $medium_aquamarine,
	'cadet_blue': $cadet_blue,
	'dark_sea_green': $dark_sea_green,
	'medium_purple': $medium_purple,
	'tomato': $tomato,
	'red': $red,
	'pale_red': $pale_red,
	'aqua': $aqua,
	'gold': $gold,
	'chocolate': $chocolate,
	'light_yellow': $light_yellow,
	'khaki': $khaki,
	'yellow_green': $yellow_green,
	'coral': $coral,
	'snow': $snow,
	'light_salmon': $light_salmon,
	'light_sky_blue': $light_sky_blue,
	'plum': $plum,
	'maroon': $maroon,
	'cornflower_blue': $cornflower_blue,
	'cornsilk': $cornsilk,
	'salmon': $salmon,
	'purple': $purple,
	'slate_gray': $slate_gray,
	'orange': $orange,
	'antique_white': $antique_white,
	'light_grey': $light_grey,
	'goldenrod': $goldenrod,
	'tan': $tan,
	'light_dim_gray': $light_dim_gray,
	'dark_goldenrod': $dark_goldenrod,
	'yellow': $yellow,
	'orchid': $orchid,
	'olive_drab': $olive_drab,
	'dark_khaki': $dark_khaki,
	'medium_orchid': $medium_orchid,
	'pale_green': $pale_green,
	'dark_slate_gray': $dark_slate_gray,
	'dark_slate_blue': $dark_slate_blue,
	'sky_blue': $sky_blue,
	'spring_green': $spring_green,
	'saddle_brown': $saddle_brown,
	'medium_slate_blue': $medium_slate_blue,
	'deep_sky_blue': $deep_sky_blue,
	'blanched_almond': $blanched_almond,
	'pale_violet_red': $pale_violet_red,
	'deep_pink': $deep_pink,
	'indigo': $indigo,
	'dark_orange': $dark_orange,
	'alice_blue': $alice_blue,
	'forest_green': $forest_green,
	'dark_violet': $dark_violet,
	'peach_puff': $peach_puff,
	'lime': $lime,
	'white': white,
	'black': black,
	'gray': gray,
	'dark_gray': $dark_gray,
	'light_gray': $light_gray,
	'cool_gray': $cool_gray,
	'fancy_gray': $fancy_gray,
);

@each $k, $v in $colors_arr {
	@if ($k != 'black' and $k != 'white' and $k != 'gray') {
		.txt-#{$k} {
			color: $v;
		}

		.bg-#{$k} {
			background-color: $v;
		}

		@for $i from 1 to 8 {
			.txt-#{$k}-light-#{$i} {
				color: mix($v, #fff, $i * 10);

				&:hover {
					color: dark-comp($v);
				}
			}
			.txt-#{$k}-dark-#{$i} {
				color: mix($v, #000, $i * 10);

				&:hover {
					color: light-comp($v);
				}
			}
		}
	}
}
