.teacher_box {
	@include flex(column, flex-start, stretch);
	box-shadow: $md-shadow;
	margin-bottom: 15px;
	padding: 10px;
	border-radius: 5px;
	border-top: 16px solid $white;

	&:hover {
		border-top: 16px solid $orange;
	}

	.teacher {
		@include flex(row, flex-start, flex-start);
		gap: 20px;

		.avatar {
			width: 100px;
			height: 100px;
			border-radius: 50%;
			overflow: hidden;
			border: 5px solid $white;
			box-shadow: $md-shadow;
			// flex: 1;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.about {
			flex: 5;
		}
	}

	.courses {
		@include flex(row, center, center);

		margin: 20px 0;
		width: 100%;
		overflow-x: auto;

		.courses_inner {
			@include flex(row, center, center);
			flex-wrap: wrap;
			// padding: 5px 15px;

			p {
				padding: 10px;
				background-color: $orange;
				// flex: 200 1 0;
				margin-right: 5px;
				border-radius: 20px;

				span {
					text-align: center;
					color: $white;
					margin: 15px;
				}
			}
		}
	}

	.detials-video {
		summary {
			color: $gold;
			padding: 5px 10px;
		}
	}

	.btns {
		@include flex(row, flex-end, center);
		margin-top: 10px;

		button {
			margin-right: 10px;
		}
	}
}

.btn_login_to_enroll {
	background-color: tomato;
	color: #fff;
	border: none;
	border-radius: 10px;
	padding: 10px 15px;
	gap: 30px;
	display: flex;
	align-items: center;
	position: relative;
	overflow: hidden;

	span:last-child {
		background-color: #fff;
		color: tomato;
		// border: 2px solid #fff;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		padding: 5px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		* {
			position: absolute;
			font-size: 2rem;
			left: 0px;
			opacity: 0;
			animation: btn_login_to_enroll 1s linear infinite;
		}
	}
}

@keyframes btn_login_to_enroll {
	from {
		opacity: 0;
		margin-left: -30px;
	}
	to {
		opacity: 0.8;
		margin-left: 30px;
	}
}
