@mixin flex($direction, $justify, $align) {
	display: flex;
	flex-direction: $direction;
	justify-content: $justify;
	align-items: $align;
}

@mixin delay($delay) {
	animation-delay: $delay;
}

$backdrop_color: rgba(0, 0, 0, 0.5);

// Fonts
@import url('https://fonts.googleapis.com/css?family=Amaranth');

// Radius
$sm_radius: 5px;
$md_radius: 10px;
$lg_radius: 15px;

// Shadow
$sm_shadow: 0 1px 3px rgba(0, 0, 0, 0.52), 0 1px 2px rgba(0, 0, 0, 0.84);
$md_shadow: 0 3px 6px rgba(0, 0, 0, 0.32), 0 3px 4px rgba(0, 0, 0, 0.64);
$lg_shadow: 0 5px 9px rgba(0, 0, 0, 0.22), 0 5px 6px rgba(0, 0, 0, 0.44);
$up_shadow: 0px -2px 2px rgba(0, 0, 0, 0.3);
$outer_shadow: 0 0 5px rgba(0, 0, 0, 0.5);

// Padding
$sm_padding: 5px;
$md_padding: 10px;
$lg_padding: 15px;

// rounded Corners
.rounded-1 {
	border-radius: 5px;
}
.rounded-2 {
	border-radius: 10px;
}
.rounded-3 {
	border-radius: 15px;
}
.rounded-4 {
	border-radius: 20px;
}
.rounded-5 {
	border-radius: 25px;
}

html,
body {
	// height: 100% !important;
	overflow-x: hidden;
}

body,
#root {
	display: flex;
	flex-direction: column;
}
#root {
	// flex-grow: 1;
}

#root_container {
	// min-height: 90%;
	// flex-grow: 1;
}
footer {
	// min-height: 10%;
}
body {
	// font-family: 'Amaranth' !important;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 20px;
}
.hand {
	cursor: pointer;
}
.login_form {
	background-color: $backdrop-color;
	position: fixed;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	z-index: 10000001;

	@include flex(column, center, center);

	.form_inner {
		background-color: $white;
		width: 85%;
		max-width: 400px;
		box-shadow: $lg-shadow;
		border-radius: $md_radius;
		padding: $md_padding;
	}
}

.pointer {
	cursor: pointer;
}

.t-x5 {
	position: relative !important;
	top: -50px !important;
	z-index: 100 !important;
}
.bg-disabled-striped {
	background-image: repeating-linear-gradient(45deg, #fff 1% 3%, #ddd 2% 4%);
}

.img-16,
.img-62,
.img-64,
.img-128 {
	display: block;
}

.img-16 {
	height: 16px;
}
.img-32 {
	height: 32px;
}
.img-64 {
	height: 64px;
}
.img-128 {
	height: 128px;
}

.fs-05 {
	font-size: 0.5rem;
}
.fs-08 {
	font-size: 0.8rem;
}
.fs-09 {
	font-size: 0.9rem;
}

// Notifications
.notification {
	position: relative;
}

.notification small {
	transform: scale(0.8);
	border-radius: 50%;
	background-color: $maroon;
	opacity: 0.6;
	color: $white;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 50%;
	top: -5px;
}

// Loading indicator
$squarew: 15px;
$squareh: 15px;
$squaremargin: 5px;
$loaderw: $squarew * 3 + $squaremargin * 2;
$loaderh: $squareh * 3 + $squaremargin * 2;
$delayenter: 0.3s;
$topenter: -10px;

@-webkit-keyframes enter {
	0% {
		opacity: 0;
		top: $topenter;
	}
	5% {
		opacity: 1;
		top: 0px;
	}
	50.9% {
		opacity: 1;
		top: 0px;
	}
	55.9% {
		opacity: 0;
		top: -$topenter;
	}
}
@keyframes enter {
	0% {
		opacity: 0;
		top: $topenter;
	}
	5% {
		opacity: 1;
		top: 0px;
	}
	50.9% {
		opacity: 1;
		top: 0px;
	}
	55.9% {
		opacity: 0;
		top: -$topenter;
	}
}
@-moz-keyframes enter {
	0% {
		opacity: 0;
		top: $topenter;
	}
	5% {
		opacity: 1;
		top: 0px;
	}
	50.9% {
		opacity: 1;
		top: 0px;
	}
	55.9% {
		opacity: 0;
		top: -$topenter;
	}
}

.loading {
	@include flex(row, center, center);
	border: 4px solid green;
	position: fixed;
	left: 0%;
	top: 0%;
	width: 100%;
	height: 100%;
	background-color: $backdrop_color;
	z-index: 10000002000;
	.square {
		background: white;
		width: $squarew;
		height: $squareh;
		float: left;
		top: $topenter;
		margin-right: $squaremargin;
		margin-top: $squaremargin;
		position: relative;
		opacity: 0;
		-webkit-animation: enter 6s infinite;
		animation: enter 6s infinite;
	}
	.enter {
		top: 0px;
		opacity: 1;
	}
	.square:nth-child(1) {
		@include delay(6 * $delayenter);
	}
	.square:nth-child(2) {
		@include delay(7 * $delayenter);
	}
	.square:nth-child(3) {
		@include delay(8 * $delayenter);
		background: #fdc96f;
	}
	.square:nth-child(4) {
		@include delay(3 * $delayenter);
	}
	.square:nth-child(5) {
		@include delay(4 * $delayenter);
	}
	.square:nth-child(6) {
		@include delay(5 * $delayenter);
	}
	.square:nth-child(8) {
		@include delay(1 * $delayenter);
	}
	.square:nth-child(9) {
		@include delay(2 * $delayenter);
	}
	.clear {
		clear: both;
	}
	.last {
		margin-right: 0;
	}
}

// Loading indicator

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

// General
// position
.pos-relative {
	position: relative;
}

.pos-absolute {
	position: absolute;
}

.my_title {
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-image: linear-gradient(45deg, $gold, $goldenrod);
}
